import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { MemberDetailsFormModel } from '../model/application-form.model';

export interface InsuredState {
  insuredDetails: MemberDetailsFormModel[];
}

export function createInitialState(): InsuredState {
  return {
    insuredDetails: []
  };
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'insured' })
export class InsuredStore extends Store<InsuredState> {
  constructor() {
    super(createInitialState());
  }
}
