import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AgentDetailsModel, AgentMemberDetailsModel, AgentRegistrationModel } from '../../model/agent.model';
import { CommonUtil } from '../../util/common.util';
import { DropdownModel } from '../../model/common.model';
import { CommonService } from '../../service/common.service';
import { DropDownQuery } from '../../query/dropdown.query';
import { ObjectUtil } from '../../util/object.util';
import { BusinessService } from '../../service/business.service';
import { NzMessageService } from 'ng-zorro-antd';
import { AgentQuery } from '../../query/agent.query';
import { Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { LoginService } from '../../service/login.service';
import { filter } from 'rxjs/operators';
import { TransmittalPaymentRecordModel } from '../../model/transmittal.model';

@Component({
  selector: 'app-agent-member',
  templateUrl: './agent-member.component.html',
  styleUrls: ['./agent-member.component.scss']
})
export class AgentMemberComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private commonService: CommonService,
    private dropDownQuery: DropDownQuery,
    private businessService: BusinessService,
    private agentQuery: AgentQuery,
    private message: NzMessageService,
    private userIdle: UserIdleService,
    private loginService: LoginService,
    private router: Router) { }

  @ViewChild('ageElement', { static: false }) ageElement: ElementRef;
  @ViewChild('ageFormGroupElement', { static: false }) ageFormGroupElement: ElementRef;
  
  protected modalTitle = '';
  protected editCache: { [key: string]: { edit: boolean; data: AgentMemberDetailsModel } } = {};
  protected isEdit = false;
  protected isEditMode = false;
  protected showRegisterModal = false;
  protected formGroup: FormGroup;
  protected registerFormGroup: FormGroup;
  protected listOfMember: Array<any>;
  protected listOfData: Array<AgentDetailsModel>;
  protected showModal = false;
  protected searchValue = '';
  protected sortValue: string | null = null;
  protected controlNumber: string | null = null;
  protected member: string | null = null;
  protected listOfBranches: Array<DropdownModel>;
  protected listOfGender: Array<DropdownModel>;
  protected typeOfPlan = [];
  protected listOfPlan = [];
  protected tempFormData = {} as AgentDetailsModel;
  protected memberDetails: Array<AgentMemberDetailsModel> = [];
  protected dateFormat = 'MM-dd-yyyy';
  protected ageValue = '';
  protected ageFormGroupValue = '';
  protected loading = false;
  protected searchName = '';
  protected pagesize = '0';
  protected paymentHistoryModal = false;
  protected listOfRecord: Array<TransmittalPaymentRecordModel> = [];

  ngOnInit() {
    this.initForm();
    this.initiateListData();
    this.initDropDownData();
    this.sessionLogout();

    this.dropDownQuery.selectError()
      .pipe(filter(err => ObjectUtil.isNotEmpty(err)))
      .subscribe(() => {
        this.message.error('Error encountered in the backend. Please contact with the admin.', {
          nzDuration: 5000
        }).onClose!.pipe().subscribe(() => {
          this.loginService.logout();
          this.router.navigateByUrl('login');
        });
      });
  }

  sessionLogout() {
    this.userIdle.startWatching();
    this.userIdle.onTimerStart().subscribe();
    this.userIdle.onTimeout().subscribe(() => {
      this.userIdle.stopTimer();
      this.userIdle.stopWatching();
      this.loginService.logout();
      this.router.navigateByUrl('login');
    });
  }

  protected initDropDownData() {
    this.commonService.getListOfGender();
    this.commonService.getListOfBranch();
    this.commonService.getListOfPlan();

    this.dropDownQuery.select()
      .subscribe(res => {
        this.listOfGender = [...res.gender];
        this.listOfBranches = [...res.branches];
        this.listOfPlan = [...res.plans];
        this.getTypeOfPlan();
      });
  }

  protected initiateListData() {
    this.loading = true;
    if (ObjectUtil.isEmpty(this.listOfData)) {
      this.businessService.agentDetails();
    }

    this.agentQuery.select()
      .pipe()
      .subscribe(res => {
        
        if(res.agentDetails !== null) {
          this.listOfData = [...res.agentDetails.agentDetails];
          this.listOfMember = [...this.listOfData];
          this.pagesize = res.agentDetails.totalSize;
          this.loading = false;
        }
        
      });
  }

  protected exportToPdf() {

    let request = this.formGroup.getRawValue() as AgentDetailsModel;
    request.id = this.tempFormData.id;
    request.birthday = CommonUtil.formatDate(request.birthday, 'MM-dd-yyyy');
    request.memberDetails = this.memberDetails;

    this.businessService.exportAgentMemberPdf(request).subscribe(response => {
      var blob = new Blob([response], { type: 'application/pdf' });

      const data = window.URL.createObjectURL(blob);
      window.open(data, '_blank');
    });
  }

  protected inputNewAgent() {
    this.showRegisterModal = true;
  }

  protected cancelRegisterModal() {
    this.showRegisterModal = false;
  }

  protected submitNewAgent() {
    let request: AgentRegistrationModel = {} as AgentRegistrationModel;
    request = this.registerFormGroup.getRawValue();
    request.birthday = CommonUtil.formatDate(request.birthday, 'MM-dd-yyyy');

    this.businessService.registerAgent(request).subscribe(() => {
      this.message.success('Agent registered successfully!');
      this.registerFormGroup.reset();
      this.showRegisterModal = false;
      this.businessService.agentDetails();

    });
  }

  protected viewDetails(data: AgentDetailsModel) {
    this.showModal = true;
    this.isEdit = false;
    this.isEditMode = false;

    this.modalTitle = 'View';

    this.setValue('dateRegistered', data, true);
    this.setValue('firstName', data, true);
    this.setValue('middleName', data, true);
    this.setValue('lastName', data, true);
    this.setValue('address', data, true);
    this.setValue('age', data, true);
    this.setValue('birthday', data, true);
    this.setValue('gender', data, true);
    this.setValue('branch', data, true);

    if (ObjectUtil.isNotEmpty(data.memberDetails)) {
      this.memberDetails = [...data.memberDetails];
      this.updateEditCache();
    }
  }

  protected editDetails(data: AgentDetailsModel) {
    Object.assign(this.tempFormData, data);
    this.showModal = true;
    this.modalTitle = 'Edit';
    this.isEdit = false;
    this.isEditMode = true;

    this.setValue('dateRegistered', data, true);
    this.setValue('firstName', data, false);
    this.setValue('middleName', data, false);
    this.setValue('lastName', data, false);
    this.setValue('address', data, false);
    this.setValue('age', data, false);
    this.setValue('birthday', data, false);
    this.setValue('gender', data, false);
    this.setValue('branch', data, false);

    if (ObjectUtil.isNotEmpty(data.memberDetails)) {
      this.memberDetails = [...data.memberDetails];
      this.updateEditCache();
    }
  }

  modalCancel(): void {
    this.showModal = false;
    this.formGroup.reset();
  }

  updateAgentDetails() {

    if (this.formGroup.valid) {
      let request = this.formGroup.getRawValue() as AgentDetailsModel;
      request.id = this.tempFormData.id;
      // request.birthday = CommonUtil.formatDate(request.birthday, 'MM-dd-yyyy');
      request.birthday = request.birthday;
      request.memberDetails = this.memberDetails;

      this.businessService.updateAgentDetails(request).subscribe(() => {
        this.message.success('Agent updated successfully!');
        this.formGroup.reset();
        this.showModal = false;
        this.businessService.agentDetails();
      });
    } else {
      for (const i in this.formGroup.controls) {
        this.formGroup.controls[i].markAsDirty();
        this.formGroup.controls[i].updateValueAndValidity();
      }
    }
  }

  protected reset(): void {
    this.loading = true;
    this.searchName = '';
    this.businessService.agentDetails();
  }

  protected search(sortBy: string): void {
    this.loading = true;
    if (sortBy === "agentName") {
      this.businessService.agentDetailsByNamePaginated(this.searchName, 1, 20);
    }
     
  }

  private initForm(): void {
    this.formGroup = this.formBuilder.group({
      dateRegistered: [{ value: '', disabled: false }, [Validators.required]],
      firstName: [{ value: '', disabled: false }, [Validators.required]],
      middleName: [{ value: '', disabled: false }, []],
      lastName: [{ value: '', disabled: false }, [Validators.required]],
      address: [{ value: '', disabled: false }, [Validators.required]],
      birthday: [{ value: '', disabled: false }, []],
      age: [{ value: '', disabled: false }, []],
      gender: [{ value: '', disabled: false }, []],
      branch: [{ value: '', disabled: false }, [Validators.required]]
    });

    this.registerFormGroup = this.formBuilder.group({
      firstName: [{ value: '', disabled: false }, [Validators.required]],
      middleName: [{ value: '', disabled: false }, []],
      lastName: [{ value: '', disabled: false }, [Validators.required]],
      address: [{ value: '', disabled: false }, [Validators.required]],
      birthday: [{ value: '', disabled: false }, []],
      age: [{ value: '', disabled: false }, []],
      gender: [{ value: '', disabled: false }, []],
      branch: [{ value: '', disabled: false }, [Validators.required]]
    });
  }

  protected startEdit(id: string): void {
    this.editCache[id].edit = true;

  }

  protected deleteRecord(id: string): void {
    this.memberDetails = [...this.memberDetails.filter(item => item.id != id)];
  }

  protected cancelEdit(id: string): void {
    const index = this.memberDetails.findIndex(item => item.id === id);
    this.editCache[id] = {
      data: { ...this.memberDetails[index] },
      edit: false
    };
  }

  protected saveEdit(id: string): void {
    const index = this.memberDetails.findIndex(item => item.id === id);

    this.editCache[id].data.dateOfApplication = CommonUtil.formatDate(this.editCache[id].data.dateOfApplication, 'MM-dd-yyyy');
    Object.assign(this.memberDetails[index], this.editCache[id].data);
    this.editCache[id].edit = false;
  }

  protected updateEditCache(): void {
    this.memberDetails.forEach(item => {
      this.editCache[item.id] = {
        edit: false,
        data: { ...item }
      };
    });
  }

  protected setValue(field: string, data: AgentDetailsModel, isReadOnly: boolean) {
    this.formGroup.get(field).setValue(data[field]);

    if (isReadOnly) {
      this.formGroup.get(field).disable({ onlySelf: isReadOnly });
    } else {
      this.formGroup.get(field).enable();
    }

  }

  getTypeOfPlan() {

    this.typeOfPlan = [];

    if (ObjectUtil.isNotEmpty(this.listOfPlan)) {
      for (var plan of this.listOfPlan) {
        this.typeOfPlan.push({ label: plan.planName, value: plan.planName });
      }
    }
  }

  onChangeTypeOfPlan(value: string, index: string) {
    if (ObjectUtil.isNotEmpty(value)) {
      let planPrice = this.listOfPlan.filter(plan => plan.planName === value)[0].price;
      this.editCache[index].data.planAmount = planPrice;
    }

  }

  protected deleteAgentDetails(id: string) {
    this.businessService.deleteAgentDetails(id).subscribe(() => {
      this.message.success('Agent deleted successfully!');
      this.businessService.agentDetails();
    });
  }

  onChangeDateOfBirth(value) {
    this.registerFormGroup.get("age").setValue(CommonUtil.getAge(value));
  }

  onChangeBirthday(value) {
    this.formGroup.get("age").setValue(CommonUtil.getAge(value));
  }

  onChangeAge(value: string): void {
    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
    if ((!isNaN(+value) && reg.test(value)) || value === '' || value === '-') {
      this.ageValue = value;
    }
    this.ageElement.nativeElement.value = this.ageValue;
  }

  onChangeFormGroupAge(value: string): void {
    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
    if ((!isNaN(+value) && reg.test(value)) || value === '' || value === '-') {
      this.ageFormGroupValue = value;
    }
    this.ageFormGroupElement.nativeElement.value = this.ageFormGroupValue;
  }

  viewPaymentHistory(data: AgentMemberDetailsModel) {
    this.paymentHistoryModal = true;
    this.businessService.getMemberPaymentRecord(data.memberId).subscribe(res => {
      this.listOfRecord = [...res['result']];
    });
    
  }

  closePaymentHistory() {
    this.paymentHistoryModal = false;
  }

  currentPageDataChange($event: number): void {
    this.loading = true;
    if($event) {
      this.searchValue = '';
      this.businessService.agentDetailsPaginated($event, 20);
      this.loading = false;
    }
  }

}
