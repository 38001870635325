import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { BranchMonthlySaleReportModel, BranchMonthlySalesReport, MonthlySalesReport } from '../../model/agent.model';
import { BranchQuery } from '../../query/branch.query';
import { BusinessService } from '../../service/business.service';
import { DropDownQuery } from '../../query/dropdown.query';
import { DropdownModel } from '../../model/common.model';
import { CommonService } from '../../service/common.service';
import { formatDate } from '@angular/common';
import { ObjectUtil } from '../../util/object.util';
import { Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { LoginService } from '../../service/login.service';
import { filter } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-branch-monthly-report',
  templateUrl: './branch-monthly-report.component.html',
  styleUrls: ['./branch-monthly-report.component.scss']
})
export class BranchMonthlyReportComponent implements OnInit {

  constructor(@Inject(LOCALE_ID) private locale: string,
    private branchQuery: BranchQuery,
    private businessService: BusinessService,
    private dropDownQuery: DropDownQuery,
    private commonService: CommonService,
    private userIdle: UserIdleService,
    private message: NzMessageService,
    private loginService: LoginService,
    private router: Router) { }

  protected listOfMonthlySalesReport: Array<BranchMonthlySalesReport>;
  protected listOfData: Array<BranchMonthlySalesReport>;
  protected listOfBranches: Array<DropdownModel>;
  protected date = null;
  protected dateOfIssuance = '';
  protected selectedValue = '';
  protected agentName: string | null = null;
  protected receiptNumber: string | null = null;
  protected searchValue = '';
  protected sortValue: string | null = null;

  ngOnInit() {
    this.commonService.getListOfBranch();

    this.dropDownQuery.select()
      .subscribe(res => {
        this.listOfBranches = [...res.branches];
      });
    this.sessionLogout();

    this.dropDownQuery.selectError()
      .pipe(filter(err => ObjectUtil.isNotEmpty(err)))
      .subscribe(() => {
        this.message.error('Error encountered in the backend. Please contact with the admin.', {
          nzDuration: 5000
        }).onClose!.pipe().subscribe(() => {
          this.loginService.logout();
          this.router.navigateByUrl('login');
        });
      });
  }

  sessionLogout() {
    this.userIdle.startWatching();
    this.userIdle.onTimerStart().subscribe();
    this.userIdle.onTimeout().subscribe(() => {
      this.userIdle.stopTimer();
      this.userIdle.stopWatching();
      this.loginService.logout();
      this.router.navigateByUrl('login');
    });
  }

  onChange(result: string): void {
    this.dateOfIssuance = formatDate(result, 'MM-dd-yyyy', this.locale);
    this.onChangeDropDown(null);
  }

  onChangeDropDown(result: string): void {
    if (ObjectUtil.isNotEmpty(this.dateOfIssuance) && ObjectUtil.isNotEmpty(this.selectedValue)) {
      this.businessService.branchMonthlyReport(this.selectedValue, this.dateOfIssuance);

      this.branchQuery.select()
        .pipe()
        .subscribe(res => {
          if (ObjectUtil.isNotEmpty(res.monthlyReport) && ObjectUtil.isNotEmpty(res.monthlyReport.monthlySalesReports)) {

            this.listOfData = [];
            for (let i = 0; i < res.monthlyReport.monthlySalesReports.length; i++) {
              let index = i+1;
              let monthlySalesReportSummary = res.monthlyReport.monthlySalesReports[i] as BranchMonthlySalesReport;
              monthlySalesReportSummary.no = index;
              this.listOfData.push(monthlySalesReportSummary);
            }

            this.listOfMonthlySalesReport = [...this.listOfData];

            let monthlySalesReportSummary = {} as BranchMonthlySalesReport;
            monthlySalesReportSummary.id = '';
            monthlySalesReportSummary.agentName = '';
            monthlySalesReportSummary.receiptAmount = res.monthlyReport.totalReceiptAmount;
            monthlySalesReportSummary.gross40Percent = res.monthlyReport.total40Gross;
            monthlySalesReportSummary.gross25Percent = res.monthlyReport.total25Gross;
            monthlySalesReportSummary.gross20Percent = res.monthlyReport.total20Gross;
            monthlySalesReportSummary.gross15Percent = res.monthlyReport.total15Gross;
            monthlySalesReportSummary.commission = res.monthlyReport.totalCommission;
            monthlySalesReportSummary.netAmount = res.monthlyReport.totalNetAmount;
            this.listOfMonthlySalesReport.push(monthlySalesReportSummary);
          } else {
            this.listOfData = [];
            this.listOfMonthlySalesReport = [...this.listOfData];
          }

        });
    } else {
      this.listOfData = [];
      this.listOfMonthlySalesReport = [...this.listOfData];
    }
  }

  exportToPdf() {
    this.businessService.exportBranchMontlySalesReportPdf(this.listOfMonthlySalesReport).subscribe(response => {
      var blob = new Blob([response], { type: 'application/pdf' });

      const data = window.URL.createObjectURL(blob);
      window.open(data, '_blank');
    });
  }

  protected search(sortBy: string): void {

    if (sortBy === 'agentName') {

      const filterFunc = (item: BranchMonthlySalesReport) => {
        return item.agentName.indexOf(this.searchValue) !== -1;
      };

      const data = this.listOfData.filter((item: BranchMonthlySalesReport) => filterFunc(item));
      this.listOfMonthlySalesReport = data.sort((a, b) =>
        this.sortValue === 'ascend'
          ? a[this.agentName!] > b[this.agentName!]
            ? 1
            : -1
          : b[this.agentName!] > a[this.agentName!]
            ? 1
            : -1
      );
    }
  }

  protected reset(sortBy: string): void {
    this.searchValue = '';
    this.search(sortBy);
  }

}
