import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AgentState, AgentStore } from '../store/agent.store';
import { EmployeeState, EmployeeStore } from '../store/employee.store';

@Injectable({
  providedIn: 'root'
})
export class EmployeeQuery extends Query<EmployeeState> {

  constructor(protected employeeStore: EmployeeStore) {
    super(employeeStore);
  }
}
