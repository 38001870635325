import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UriConstants } from '../constant/uri.constants';
import { MemberDetailsFormModel } from '../model/application-form.model';
import { TransmittalModel, TransmittalResponse } from '../model/transmittal.model';
import { TransmittalStore } from '../store/transmittal.store';
import { AgentDetailsModel, AgentDetailsResponseHandler, AgentMonthlySaleReportModel, AgentRegistrationModel, BranchMonthlySaleReportModel } from '../model/agent.model';
import { AgentStore } from '../store/agent.store';
import { ReceiptStore } from '../store/receipt.store';
import { ReceiptRecordsModel, ReceiptRecordsPaginatedModel, ReceiptRecordsRequestModel } from '../model/receipt-records.model';
import { MonthlyCasualtyRecordModel } from '../model/casualty.model';
import { CasualtyStore } from '../store/casualty.store';
import { InsuredStore } from '../store/insured.store';
import { EmployeeModel, LoginModelRequest } from '../model/employee.model';
import { EmployeeStore } from '../store/employee.store';
import { DashboardStore } from '../store/dashboard.store';
import { DashboardModel } from '../model/dashboard.model';
import { BranchStore } from '../store/branch.store';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  constructor(
    private http: HttpClient,
    private transmittalStore: TransmittalStore,
    private agentStore: AgentStore,
    private receiptStore: ReceiptStore,
    private casualtyStore: CasualtyStore,
    private insuredStore: InsuredStore,
    private employeeStore: EmployeeStore,
    private dashboardStore: DashboardStore,
    private branchStore: BranchStore) { }

  public applicationForm(request: MemberDetailsFormModel) {
    return this.http.post(UriConstants.BUSINESS_APPLICATION_FORM, request);
  }

  public registerAgent(request: AgentRegistrationModel) {
    return this.http.post(UriConstants.BUSINESS_REGISTER_NEW_AGENT, request);
  }

  public updateMember({ request }: { request: TransmittalModel; }) {
    return this.http.post(UriConstants.BUSINESS_UPDATE_MEMBER, request);
  }


  public deleteMemberDetails(id: string) {
    return this.http.post(UriConstants.BUSINESS_DELETE_MEMBER, id);
  }

  public updateAgentDetails(request: AgentDetailsModel) {
    return this.http.post(UriConstants.BUSINESS_UPDATE_AGENT, request);
  }

  public agentDetails() {
    this.agentDetailsPaginated(1, 20);
  }

  public agentDetailsPaginated(pageNo: number, pageSize: number) {
    const pageNoStr = Number(pageNo) - 1;

    this.http.get<{ result: AgentDetailsResponseHandler }>(UriConstants.BUSINESS_AGENT_DETAILS,
      {
        params: {
          pageNo: pageNoStr.toString(),
          pageSize: pageSize.toString()
        }
      }
    )
    .pipe()
    .subscribe(res => {
      this.agentStore.update({ agentDetails: res.result });
    }, error => {
      this.agentStore.setError(error);
    });
  }

  public agentDetailsByNamePaginated(name: string, pageNo: number, pageSize: number) {
    const pageNoStr = Number(pageNo) - 1;

    console.log(name);
    this.http.get<{ result: AgentDetailsResponseHandler }>(UriConstants.BUSINESS_AGENT_DETAILS_NAME,
      {
        params: {
          name: name,
          pageNo: pageNoStr.toString(),
          pageSize: pageSize.toString()
        }
      }
    )
    .pipe()
    .subscribe(res => {
      this.agentStore.update({ agentDetails: res.result });
    }, error => {
      this.agentStore.setError(error);
    });
  }

  public deleteAgentDetails(id: string) {
    return this.http.post(UriConstants.BUSINESS_DELETE_AGENT_DETAILS, id);
  }

  public memberTransmittal() {
    this.memberTransmittalPagination(1, 20);
  }

  public memberTransmittalPagination(pageNo: number, pageSize: number) {

    const pageNoStr = Number(pageNo) - 1;

    this.http.get<{ result: TransmittalResponse }>(UriConstants.BUSINESS_TRANSMITTAL, {
      params: {
        pageNo: pageNoStr.toString(),
        pageSize: pageSize.toString()
      }
    })
    .pipe()
    .subscribe(res => {
      this.transmittalStore.update({  transmittal: res.result });
    }, error => {
      this.transmittalStore.setError(error);
    });
  }

  public searchTransmitalRecord(searchTransmittalRecordRequest) {
    return this.http.post<{ result: TransmittalResponse }>(UriConstants.BUSINESS_MEMBER_SEARCH_TRANSMITTAL, searchTransmittalRecordRequest)
    .pipe()
    .subscribe(res => {
      this.transmittalStore.update({  transmittal: res.result });
    }, error => {
      this.transmittalStore.setError(error);
    });;
  }

  public receiptDetails(orType: string) {
    this.receiptDetailsPage(orType, '1', '20');
  }

  public receiptDetailsPage(orType: string, pageNo: string, pageSize: string) {

    const pageNoStr = Number(pageNo) - 1;

    this.http.get<{ result: ReceiptRecordsPaginatedModel }>(UriConstants.BUSINESS_RECEIPT_RECORDS, {
      params: {
        orType: orType,
        pageNo: pageNoStr.toString(),
        pageSize: pageSize
      }
    })
    .pipe()
    .subscribe(res => {
      this.receiptStore.update({ receiptDetails: res.result });
    }, error => {
      this.receiptStore.setError(error);
    });
  }

  public receiptSearchRecords(request) {
    return this.http.post<{ result: ReceiptRecordsPaginatedModel }>(UriConstants.BUSINESS_RECEIPT_SEARCH_RECORDS, request)
    .pipe()
    .subscribe(res => {
      this.receiptStore.update({ receiptDetails: res.result });
    }, error => {
      this.receiptStore.setError(error);
    });;
  }

  public addReceiptDetails(request: ReceiptRecordsRequestModel) {
    return this.http.post(UriConstants.BUSINESS_ADD_RECEIPT_RECORDS, request);
  }

  public editReceiptDetails(request: ReceiptRecordsRequestModel) {
    return this.http.post(UriConstants.BUSINESS_EDIT_RECEIPT_RECORDS, request);
  }

  public deleteReceiptDetails(id: string) {
    return this.http.post(UriConstants.BUSINESS_DELETE_RECEIPT_RECORDS, id);
  }

  public memberDetails(id: string) {
    return this.http.post(UriConstants.BUSINESS_MEMBER_DETAILS, id);
  }

  public casualtyDetails() {
    this.http.get<{ result: MonthlyCasualtyRecordModel[] }>(UriConstants.BUSINESS_CASUALTY_DETAILS)
    .pipe()
    .subscribe(res => {
      this.casualtyStore.update({ casualtyDetails : res.result });
    }, error => {
      this.casualtyStore.setError(error);
    });
  }

  public addCasualty(request: MonthlyCasualtyRecordModel) {
    return this.http.post(UriConstants.BUSINESS_ADD_CASUALTY_DETAILS, request);
  }

  public deleteCasualty(id: string) {
    return this.http.post(UriConstants.BUSINESS_DELETE_CASUALTY_DETAILS, id);
  }

  public insuredDetails(status: string) {
    this.http.get<{ result: MemberDetailsFormModel[] }>(UriConstants.BUSINESS_INSURED_DETAILS, {
      params: {
        status: status
      }
    })
    .pipe()
    .subscribe(res => {
      this.insuredStore.update({ insuredDetails : res.result });
    }, error => {
      this.insuredStore.setError(error);
    });
  }

  public updateMemberDetails(request: MemberDetailsFormModel) {
    return this.http.post(UriConstants.BUSINESS_UPDATE_MEMBER_DETAILS, request);
  }

  public getReceiptRecordsByAgent(id: string, date: string) {

    let request = {
      id: id,
      date: date
    };

    return this.http.post<{ result: AgentMonthlySaleReportModel }>(UriConstants.BUSINESS_AGENT_RECEIPT_RECORDS, request)
    .pipe()
    .subscribe(res => {
      this.agentStore.update({ monthlyReport: res.result });
    }, error => {
      this.agentStore.setError(error);
    });
  }

  public registerNewEmployee(request: EmployeeModel) {
    return this.http.post(UriConstants.BUSINESS_REGISTER_EMPLOYEE, request);
  }

  public getEmployeeDetails() {
    return this.http.get<{ result: EmployeeModel[] }>(UriConstants.BUSINESS_EMPLOYEE_DETAILS)
    .pipe()
    .subscribe(res => {
      this.employeeStore.update({ employeeDetails: res.result });
    }, error => {
      this.employeeStore.setError(error);
    });
  }

  public registerNewCredentials(request: LoginModelRequest) {
    return this.http.post(UriConstants.BUSINESS_REGISTER_CREDENTIALS, request);
  }

  public updateEmployee(request: EmployeeModel) {
    return this.http.post(UriConstants.BUSINESS_UPDATE_EMPLOYEE, request);
  }

  public deleteEmployee(id: string) {
    return this.http.post(UriConstants.BUSINESS_DELETE_EMPLOYEE, id);
  }

  public exportPdf(request: any) {
    return this.http.post(UriConstants.BUSINESS_EMPLOYEE_PDF, request, {
      headers: {
        "Accept": "application/pdf"
      },
      responseType: "blob"
    });
  }

  public dashboardDetails() {
    this.http.get<{ result: DashboardModel }>(UriConstants.BUSINESS_DASHBOARD_DETAILS)
    .pipe()
    .subscribe(res => {
      this.dashboardStore.update({ dashboardDetails : res.result });
    }, error => {
      this.dashboardStore.setError(error);
    });
  }

  public branchMonthlyReport(branchName: string, date: string) {
    let request = {
      id: branchName,
      date: date
    };
    return this.http.post<{ result: BranchMonthlySaleReportModel }>(UriConstants.BUSINESS_BRANCH_MONTHLY_REPORT, request)
    .pipe()
    .subscribe(res => {
      this.branchStore.update({ monthlyReport: res.result });
    }, error => {
      this.branchStore.setError(error);
    });
  }

  public exportTransmittalPdf(request: any) {
    return this.http.post(UriConstants.BUSINESS_TRANSMITTAL_PDF, request, {
      headers: {
        "Accept": "application/pdf"
      },
      responseType: "blob"
    });
  }

  public exportMemberPdf(request: any) {
    return this.http.post(UriConstants.BUSINESS_MEMBER_PDF, request, {
      headers: {
        "Accept": "application/pdf"
      },
      responseType: "blob"
    });
  }

  public exportAgentMemberPdf(request: any) {
    return this.http.post(UriConstants.BUSINESS_AGENT_MEMBER_PDF, request, {
      headers: {
        "Accept": "application/pdf"
      },
      responseType: "blob"
    });
  }

  public exportAgentMonthlySaleReportPdf(request: any) {
    return this.http.post(UriConstants.BUSINESS_AGENT_MONTHLY_SALES_REPORT_PDF, request, {
      headers: {
        "Accept": "application/pdf"
      },
      responseType: "blob"
    });
  }

  public exportBranchMontlySalesReportPdf(request: any) {
    return this.http.post(UriConstants.BUSINESS_BRANCH_MONTHLY_SALES_REPORT_PDF, request, {
      headers: {
        "Accept": "application/pdf"
      },
      responseType: "blob"
    });
  }

  public exportMonthtyCasualtyRecordPdf(request: any) {
    return this.http.post(UriConstants.BUSINESS_MONTHLY_CASUALTY_REPORT_PDF, request, {
      headers: {
        "Accept": "application/pdf"
      },
      responseType: "blob"
    });
  }

  public exportReceiptRecordPdf(request: any) {
    return this.http.post(UriConstants.BUSINESS_RECEIPT_PDF, request, {
      headers: {
        "Accept": "application/pdf"
      },
      responseType: "blob"
    });
  }

  public checkMemberDetailsIfExist(request: MemberDetailsFormModel) {
    return this.http.post(UriConstants.BUSINESS_MEMBER_DETAILS_EXIST, request);
  }

  public getMemberPaymentRecord(memberId: string) {
    return this.http.get(UriConstants.BUSINESS_AGENT_PAYMENT_RECORD, {
      params: {
        memberId: memberId
      }
    });
  }

}
