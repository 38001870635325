import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { CasualtyState, CasualtyStore } from '../store/casualty.store';

@Injectable({
  providedIn: 'root'
})
export class CasualtyQuery extends Query<CasualtyState> {

  constructor(protected casualtyStore: CasualtyStore) {
    super(casualtyStore);
  }
}
