import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { MonthlyCasualtyRecordModel } from '../model/casualty.model';

export interface CasualtyState {
  casualtyDetails: MonthlyCasualtyRecordModel[];
}

export function createInitialState(): CasualtyState {
  return {
    casualtyDetails: []
  };
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'casualty' })
export class CasualtyStore extends Store<CasualtyState> {
  constructor() {
    super(createInitialState());
  }
}
