import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AgentDetailsModel, AgentMonthlySaleReportModel, MonthlySalesReport } from '../../model/agent.model';
import { BusinessService } from '../../service/business.service';
import { AgentQuery } from '../../query/agent.query';
import { ObjectUtil } from '../../util/object.util';
import { DropdownModel } from '../../model/common.model';
import { DropDownQuery } from '../../query/dropdown.query';
import { CommonService } from '../../service/common.service';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { LoginService } from '../../service/login.service';
import { filter } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-agent-records',
  templateUrl: './agent-records.component.html',
  styleUrls: ['./agent-records.component.scss']
})
export class AgentRecordsComponent implements OnInit {

  constructor(@Inject(LOCALE_ID) private locale: string,
    private businessService: BusinessService,
    private commonService: CommonService,
    private dropDownQuery: DropDownQuery,
    private agentQuery: AgentQuery,
    private userIdle: UserIdleService,
    private loginService: LoginService,
    private router: Router,
    private message: NzMessageService) { }

  protected formGroup: FormGroup;
  protected listOfMonthlySalesReport: Array<MonthlySalesReport>;
  protected listOfData: Array<MonthlySalesReport>;
  protected showModal = false;
  protected searchValue = '';
  protected sortValue: string | null = null;
  protected memberName: string | null = null;
  protected receiptNumber: string | null = null;
  protected branchName = '';
  protected agentDetails: Array<AgentDetailsModel>;
  protected selectedValue = '';
  protected listOfAgent: Array<DropdownModel>;
  protected isPasswordVisible = false;
  protected isConfirmPasswordVisible = false;
  protected date = null;
  protected dateOfIssuance = '';

  ngOnInit() {

    this.commonService.getListOfAgent();

    this.dropDownQuery.select()
      .subscribe(res => {
        this.listOfAgent = [...res.agents];
      });

    this.getAgentDetails();

    this.sessionLogout();

    this.dropDownQuery.selectError()
      .pipe(filter(err => ObjectUtil.isNotEmpty(err)))
      .subscribe(() => {
        this.message.error('Error encountered in the backend. Please contact with the admin.', {
          nzDuration: 5000
        }).onClose!.pipe().subscribe(() => {
          this.loginService.logout();
          this.router.navigateByUrl('login');
        });
      });
  }

  sessionLogout() {
    this.userIdle.startWatching();
    this.userIdle.onTimerStart().subscribe();
    this.userIdle.onTimeout().subscribe(() => {
      this.userIdle.stopTimer();
      this.userIdle.stopWatching();
      this.loginService.logout();
      this.router.navigateByUrl('login');
    });
  }

  protected getAgentDetails() {

    if (ObjectUtil.isEmpty(this.agentDetails)) {
      this.businessService.agentDetailsPaginated(1, 0);
    }

    this.agentQuery.select()
      .pipe()
      .subscribe(res => {
        if(res.agentDetails !== null) {
          this.agentDetails = [...res.agentDetails.agentDetails];
        }
      });
  }

  protected exportToPdf() {
    this.businessService.exportAgentMonthlySaleReportPdf(this.listOfMonthlySalesReport).subscribe(response => {
      var blob = new Blob([response], { type: 'application/pdf' });

      const data = window.URL.createObjectURL(blob);
      window.open(data, '_blank');
    });
  }

  protected encodeMonthlySales() {
    this.showModal = true;
  }

  modalCancel(): void {
    this.showModal = false;
    this.formGroup.reset();
  }

  submitForm() {
    for (const i in this.formGroup.controls) {
      this.formGroup.controls[i].markAsDirty();
      this.formGroup.controls[i].updateValueAndValidity();
    }
  }

  protected reset(sortBy: string): void {
    this.searchValue = '';
    this.search(sortBy);
  }

  protected search(sortBy: string): void {

    if (sortBy === 'memberName') {

      const filterFunc = (item: MonthlySalesReport) => {
        return item.memberName.indexOf(this.searchValue) !== -1;
      };

      const data = this.listOfData.filter((item: MonthlySalesReport) => filterFunc(item));
      this.listOfMonthlySalesReport = data.sort((a, b) =>
        this.sortValue === 'ascend'
          ? a[this.memberName!] > b[this.memberName!]
            ? 1
            : -1
          : b[this.memberName!] > a[this.memberName!]
            ? 1
            : -1
      );
    } else if (sortBy === 'receiptNumber') {
      const filterFunc = (item: MonthlySalesReport) => {
        return item.receiptNumber.indexOf(this.searchValue) !== -1;
      };

      const data = this.listOfData.filter((item: MonthlySalesReport) => filterFunc(item));
      this.listOfMonthlySalesReport = data.sort((a, b) =>
        this.sortValue === 'ascend'
          ? a[this.receiptNumber!] > b[this.receiptNumber!]
            ? 1
            : -1
          : b[this.receiptNumber!] > a[this.receiptNumber!]
            ? 1
            : -1
      );
    }
  }

  protected monthlyReport(data) {

    if (ObjectUtil.isNotEmpty(this.dateOfIssuance)
        && ObjectUtil.isNotEmpty(this.selectedValue) 
        && ObjectUtil.isNotEmpty(this.agentDetails)) {

      this.branchName = this.agentDetails.filter(value => value.id === this.selectedValue)[0].branch;
      this.businessService.getReceiptRecordsByAgent(this.selectedValue, this.dateOfIssuance);


      this.agentQuery.select()
        .pipe()
        .subscribe(res => {
          if (ObjectUtil.isNotEmpty(res.monthlyReport) && ObjectUtil.isNotEmpty(res.monthlyReport.monthlySalesReports)) {

            this.listOfData = [];

            for (let i = 0; i < res.monthlyReport.monthlySalesReports.length; i++) {
              let index = i+1;
              let monthlySalesReportSummary = res.monthlyReport.monthlySalesReports[i] as MonthlySalesReport;
              monthlySalesReportSummary.no = index;
              this.listOfData.push(monthlySalesReportSummary);
            }

            // this.listOfData = [...res.monthlyReport.monthlySalesReports];
            this.listOfMonthlySalesReport = [...this.listOfData];

            let monthlySalesReportSummary = {} as MonthlySalesReport;
            monthlySalesReportSummary.id = '';
            monthlySalesReportSummary.memberName = '';
            monthlySalesReportSummary.dateOfIssuance = '';
            monthlySalesReportSummary.receiptNumber = '';
            monthlySalesReportSummary.receiptAmount = '';
            monthlySalesReportSummary.receiptAmount = res.monthlyReport.receiptAmount;
            monthlySalesReportSummary.gross40Percent = res.monthlyReport.total40Gross;
            monthlySalesReportSummary.gross25Percent = res.monthlyReport.total25Gross;
            monthlySalesReportSummary.gross20Percent = res.monthlyReport.total20Gross;
            monthlySalesReportSummary.gross15Percent = res.monthlyReport.total15Gross;
            monthlySalesReportSummary.commission = res.monthlyReport.totalCommission;
            monthlySalesReportSummary.netAmount = res.monthlyReport.totalNetAmount;
            this.listOfMonthlySalesReport.push(monthlySalesReportSummary);
          } else {
            this.listOfData = [];
            this.listOfMonthlySalesReport = [...this.listOfData];
          }
        });
    } else {
      this.listOfData = [];
      this.listOfMonthlySalesReport = [...this.listOfData];
    }

  }

  onChange(result: Date): void {
    this.dateOfIssuance = formatDate(result, 'MM-dd-yyyy', this.locale);
    
    this.monthlyReport(null);

  }

}
