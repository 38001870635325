import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { BranchState, BranchStore } from '../store/branch.store';

@Injectable({
  providedIn: 'root'
})
export class BranchQuery extends Query<BranchState> {

  constructor(protected branchStore: BranchStore) {
    super(branchStore);
  }
}
