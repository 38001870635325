import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../service/login.service';
import { StorageUtil } from '../../util/storage.util';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  constructor(private router: Router, private loginService: LoginService) { }

  public isCollapsed: boolean;

  public currentYear: any;

  public sideMenu = [];

  public username = '';

  ngOnInit() {
    this.initVariables();
    this.currentYear = new Date().getFullYear();

    this.username = StorageUtil.GET('username');

    if(StorageUtil.GET('userRole') === 'Staff') {
      this.sideMenu = [
        {
          name: 'Dashboard',
          icon: 'dashboard',
          path: 'dashboard',
          subMenu: []
        }, {
          name: 'Member',
          icon: 'audit',
          path: '',
          subMenu: [{
            name: 'Application Form',
            path: 'application-form',
            subMenu: []
          },
          {
            name: 'Record / Transmital',
            path: 'transmittal',
            subMenu: []
          }, {
            name: 'Insured Members',
            path: 'insured-members',
            subMenu: []
          }
          ]
        }, {
          name: 'Agent',
          icon: 'usergroup-add',
          path: '',
          subMenu: [{
            name: 'Monthly Sales Report',
            path: 'agent-records',
            subMenu: []
          }]
        }, {
          name: 'E-Board',
          icon: 'bar-chart',
          path: '',
          subMenu: [
            {
              name: 'Monthly Casualty Records',
              path: 'monthly-casualty-records',
              subMenu: []
            }, {
              name: 'Receipt Records',
              path: 'receipt-records',
              subMenu: []
            }]
        }];
    } else {
      this.sideMenu = [
        {
          name: 'Dashboard',
          icon: 'dashboard',
          path: 'dashboard',
          subMenu: []
        }, {
          name: 'Member',
          icon: 'audit',
          path: '',
          subMenu: [{
            name: 'Application Form',
            path: 'application-form',
            subMenu: []
          },
          {
            name: 'Record / Transmittal',
            path: 'transmittal',
            subMenu: []
          }, {
            name: 'Insured Members',
            path: 'insured-members',
            subMenu: []
          }
          ]
        }, {
          name: 'Agent',
          icon: 'usergroup-add',
          path: '',
          subMenu: [{
            name: 'Member List',
            path: 'agent-members',
            subMenu: []
          }, {
            name: 'Monthly Sales Report',
            path: 'agent-records',
            subMenu: []
          }]
        }, {
          name: 'E-Board',
          icon: 'bar-chart',
          path: '',
          subMenu: [
            {
              name: 'Branch Monthly Report',
              path: 'branch-monthly-report',
              subMenu: []
            }, {
              name: 'Monthly Casualty Records',
              path: 'monthly-casualty-records',
              subMenu: []
            }, {
              name: 'Receipt Records',
              path: 'receipt-records',
              subMenu: []
            }, {
              name: 'Employee Details',
              path: 'employee-details',
              subMenu: []
            }]
        }];
    }
  }

  private initVariables(): void {
    this.isCollapsed = false;
  }

  public logout() {
    this.loginService.logout();
    this.router.navigateByUrl('login');
  }
}
