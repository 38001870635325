import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { TransmittalModel } from '../model/transmittal.model';
import { TransmittalState, TransmittalStore } from '../store/transmittal.store';

@Injectable({
  providedIn: 'root'
})
export class TransmittalQuery extends Query<TransmittalState> {

  constructor(protected transmittalStore: TransmittalStore) {
    super(transmittalStore);
  }
}
