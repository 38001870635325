import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { AgentDetailsModel, AgentMonthlySaleReportModel } from '../model/agent.model';
import { DashboardModel } from '../model/dashboard.model';

export interface DashboardState {
  dashboardDetails: DashboardModel;
}

export function createInitialState(): DashboardState {
  return {
    dashboardDetails: {totalActiveAgent: 0, totalActiveMember: 0, totalCasualty: 0, totalEmployees: 0} as DashboardModel
  };
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'dashboard' })
export class DashboardStore extends Store<DashboardState> {
  constructor() {
    super(createInitialState());
  }
}
