import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DropdownModel, DropdownPlanModel } from '../../model/common.model';
import { CommonService } from '../../service/common.service';
import { DropDownQuery } from '../../query/dropdown.query';
import { ObjectUtil } from '../../util/object.util';
import { MemberDetailsFormModel, BeneficiaryData } from '../../model/application-form.model';
import { CommonUtil } from '../../util/common.util';
import { BusinessService } from '../../service/business.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { NzMessageService, NzModalRef, NzModalService } from 'ng-zorro-antd';
import { MemberDetails } from '../../model/member-details.model';

@Component({
  selector: 'app-application-form',
  templateUrl: './application-form.component.html',
  styleUrls: ['./application-form.component.scss']
})
export class ApplicationFormComponent implements OnInit {

  @ViewChild('contactNumberElement', { static: false }) contactNumberElement: ElementRef;
  @ViewChild('ageElement', { static: false }) ageElement: ElementRef;

  i = 0;
  formatterCurrency = (value: number) => `₱ ${value}`;
  parserCurrency = (value: string) => value.replace('₱ ', '');
  editCache: { [key: string]: { edit: boolean; data: BeneficiaryData } } = {};
  listOfBeneficiaryData: BeneficiaryData[] = [];
  formGroup: FormGroup;
  protected value = '';
  protected ageValue = '';
  protected listOfAgent: Array<DropdownModel>;
  protected listOfFilteredAgent: Array<DropdownModel>;
  protected listOfPlanDisplay: Array<DropdownModel>;
  protected listOfGender: Array<DropdownModel>;
  protected listOfBranches: Array<DropdownModel>;
  protected listOfPlan: Array<DropdownPlanModel>;
  protected memberType: Array<DropdownModel>;
  protected confirmModal: NzModalRef;
  protected listOfORType: Array<DropdownModel>;
  protected percentage = [];

  constructor(private formBuilder: FormBuilder,
    private commonService: CommonService,
    private dropDownQuery: DropDownQuery,
    private businessService: BusinessService,
    private message: NzMessageService,
    private modal: NzModalService) { }

  ngOnInit(): void {
    this.initForm();
    this.initData();
  }


  protected initForm() {
    this.formGroup = this.formBuilder.group({
      firstName: [null, [Validators.required]],
      middleName: [null, []],
      lastName: [null, [Validators.required]],
      dateOfBirth: [],
      age: [],
      gender: [],
      placeOfBirth: [],
      occupation: [],
      permanentAddress: [null, [Validators.required]],
      contactNumber: [],
      typeOfPlan: [null, [Validators.required]],
      planPrice: [{ value: '', disabled: true }, [Validators.required]],
      membershipFee: ['', [Validators.required]],
      orNumber: [null, [Validators.required]],
      orType: [null, [Validators.required]],
      agent: [null, [Validators.required]],
      branch: [null, [Validators.required]],
      dateSigned: [null, [Validators.required]],
      memberType: [null, [Validators.required]],
      percentage: [null, [Validators.required]]
    });

  }

  protected calculateCommission(percent): string {
    const amount = this.formGroup.get('membershipFee').value;
    let percentage = 0;
    let calculatedValue = '';

    if (!!amount) {
      percentage = (parseInt(amount) * parseInt(percent)) / 100;
      calculatedValue = percentage.toString();
    }

    return calculatedValue;
  }


  protected submitForm(): void {
    
    const form = this.formGroup;
    if (form.valid) {
      let request: MemberDetailsFormModel = {} as MemberDetailsFormModel;
      request = form.getRawValue();

      if (ObjectUtil.isNotEmpty(request.percentage)) {
        switch (request.percentage) {
          case '40': {
            request.gross40Percent = this.calculateCommission(request.percentage);
            request.gross25Percent = '';
            request.gross20Percent = '';
            request.gross15Percent = '';
            break;
          }
          case '25': {
            request.gross25Percent = this.calculateCommission(request.percentage);
            request.gross40Percent = '';
            request.gross20Percent = '';
            request.gross15Percent = '';
            break;
          }
          case '20': {
            request.gross20Percent = this.calculateCommission(request.percentage);
            request.gross25Percent = '';
            request.gross40Percent = '';
            request.gross15Percent = '';
            break;
          }
          case '15': {
            request.gross15Percent = this.calculateCommission(request.percentage);
            request.gross25Percent = '';
            request.gross20Percent = '';
            request.gross40Percent = '';
            break;
          }
          default: {
            break;
          }
        }
      }

      // request.dateSigned = CommonUtil.formatDate(request.dateSigned, 'MM-dd-yyyy');
      // request.dateOfBirth = CommonUtil.formatDate(request.dateOfBirth, 'MM-dd-yyyy');
      request.beneficiaryDetails = [... this.listOfBeneficiaryData];

      let agentName = this.listOfAgent.filter(option => option.label.toLowerCase().indexOf(request.agent.toLowerCase()) !== -1);
      if (!!agentName && !!agentName[0] && !!agentName[0].value) {
        request.agent = agentName[0].value;
      }

      this.businessService.checkMemberDetailsIfExist(request).pipe(catchError((err) => {
        this.message.error('There is an error on the backend please contact your admin!');
        return throwError(err);
      })).subscribe(resp => {

        const response: MemberDetails = resp['result'] as MemberDetails;
        if (ObjectUtil.isNotEmpty(response.fullname) && ObjectUtil.isNotEmpty(response.agent)) {
          this.showConfirm(response.fullname, response.agent, response.status, request);
        } else {
          this.saveNewApplication(request);
        }
      });



    } else {
      for (const i in this.formGroup.controls) {
        this.formGroup.controls[i].markAsDirty();
        this.formGroup.controls[i].updateValueAndValidity();
      }
    }
  }

  saveNewApplication(request: MemberDetailsFormModel) {
    this.businessService.applicationForm(request).pipe(catchError((err) => {
      this.message.error('There is error on saving!');
      return throwError(err);
    })).subscribe(() => {
      this.message.success('Saved!');
      this.formGroup.reset();
      this.formGroup.get('membershipFee').setValue('');
      this.contactNumberElement.nativeElement.value = '';
      this.ageElement.nativeElement.value = '';
      this.listOfBeneficiaryData = [];
    });
  }

  showConfirm(fullName: string, agentName: string, status: string,request: MemberDetailsFormModel): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Member Exist',
      nzContent: 'Member Name: '+ fullName +' <br/> Agent Name: ' + agentName + '<br/> Status: ' + status,
      nzOkText: 'Proceed',
      nzCancelText: 'Cancel',
      nzOnOk: () =>
        this.saveNewApplication(request)
    });
  }

  initData() {
    this.initDropDownData();

    this.memberType = [{
      value: 'Insured', 
      label: 'Insured'
    }, {
      value: 'Uninsured', 
      label: 'Uninsured'
    }];

    this.listOfORType = [{
      label: 'Provisionary',
      value: 'Provisionary'
    },{
      label: 'Temporary',
      value: 'Temporary'
    }];

    this.percentage = [
      {
        label: '40%',
        value: '40'
      }, {
        label: '25%',
        value: '25'
      }, {
        label: '20%',
        value: '20'
      }, {
        label: '15%',
        value: '15'
      }];

    this.dropDownQuery.select()
      .subscribe(res => {
        this.listOfAgent = [...res.agents];
        this.listOfFilteredAgent = [...this.listOfAgent];
        this.listOfGender = [...res.gender];
        this.listOfBranches = [...res.branches];
        this.listOfPlan = [...res.plans];
        this.getTypeOfPlan();
      });
  }

  getTypeOfPlan() {
    this.listOfPlanDisplay = [];

    if (ObjectUtil.isNotEmpty(this.listOfPlan)) {
      for (var plan of this.listOfPlan) {
        this.listOfPlanDisplay.push({ label: plan.planName, value: plan.planName });
      }
    }
  }

  protected initDropDownData() {
    this.commonService.getListOfAgent();
    this.commonService.getListOfGender();
    this.commonService.getListOfBranch();
    this.commonService.getListOfPlan();
  }

  onChangeTypeOfPlan(value: string) {
    if (ObjectUtil.isNotEmpty(value)) {
      let planPrice = this.listOfPlan.filter(plan => plan.planName === value)[0].memberPrice;
      this.formGroup.get("planPrice").setValue(planPrice);
    }
  }

  onChangeAge(value: string): void {
    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
    if ((!isNaN(+value) && reg.test(value)) || value === '' || value === '-') {
      this.ageValue = value;
    }
    this.ageElement.nativeElement.value = this.ageValue;
  }

  onChangeInputNumber(value: string): void {
    this.updateValue(value);
  }

  updateValue(value: string): void {
    const reg = /^-?([0-9]*)(\.[0-9]*)?$/;
    if ((!isNaN(+value) && reg.test(value)) || value === '' || value === '-') {
      this.value = value;
    }
    this.contactNumberElement.nativeElement.value = this.value;
  }

  addRow(): void {

    if (this.listOfBeneficiaryData.length < 3) {
      this.listOfBeneficiaryData = [
        ...this.listOfBeneficiaryData,
        {
          id: `${this.i}`,
          firstName: ``,
          middleName: ``,
          lastName: ``,
          address: ``,
          contactNumber: ``,
          relationship: ``
        }
      ];
      this.i++;

      this.updateEditCache();
    }
  }

  startEdit(id: string): void {
    this.editCache[id].edit = true;
  }

  cancelEdit(id: string): void {
    this.listOfBeneficiaryData = this.listOfBeneficiaryData.filter(d => d.id !== id);
  }

  saveEdit(id: string): void {
    const index = this.listOfBeneficiaryData.findIndex(item => item.id === id);
    Object.assign(this.listOfBeneficiaryData[index], this.editCache[id].data);
    this.editCache[id].edit = false;
  }

  updateEditCache(): void {
    this.listOfBeneficiaryData.forEach(item => {
      this.editCache[item.id] = {
        edit: true,
        data: { ...item }
      };
    });
  }

  onChangeDateOfBirth(value) {
    this.formGroup.get("age").setValue(CommonUtil.getAge(value));
  }

  onChangeAgent(value: string): void {
    if (ObjectUtil.isNotEmpty(value)) {
      this.listOfFilteredAgent = this.listOfAgent.filter(option => option.label.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.listOfFilteredAgent = [...this.listOfAgent];
    }
  }
}

