import { environment } from 'src/environments/environment';

export class UriConstants {

  public static readonly BASE_URL = environment.baseUrl;

  public static readonly USER_SAVE = environment.baseUrl + 'user/save';

  public static readonly USER_FIND_ALL = environment.baseUrl + 'user/findAll';

  public static readonly USER_DELETE = environment.baseUrl + 'user/delete';

  public static readonly LOGIN = environment.baseUrl + 'login';

  public static readonly VERIFY_TOKEN = environment.baseUrl + 'verifyToken';

  public static readonly COMMON_MEMBER_LIST = environment.baseUrl + 'common/members';

  public static readonly COMMON_AGENT_LIST = environment.baseUrl + 'common/agents';

  public static readonly COMMON_GENDER_LIST = environment.baseUrl + 'common/gender';

  public static readonly COMMON_BRANCH_LIST = environment.baseUrl + 'common/branch';

  public static readonly COMMON_PLAN_LIST = environment.baseUrl + 'common/plans';

  public static readonly BUSINESS_APPLICATION_FORM = environment.baseUrl + 'business/member/application';

  public static readonly BUSINESS_DELETE_MEMBER = environment.baseUrl + 'business/member/delete';

  public static readonly BUSINESS_TRANSMITTAL = environment.baseUrl + 'business/member/transmital/record';

  public static readonly BUSINESS_UPDATE_MEMBER = environment.baseUrl + 'business/member/transmital/update';

  public static readonly BUSINESS_REGISTER_NEW_AGENT = environment.baseUrl + 'business/agent/register';

  public static readonly BUSINESS_AGENT_DETAILS = environment.baseUrl + 'business/agent/details';

  public static readonly BUSINESS_AGENT_DETAILS_NAME = environment.baseUrl + 'business/agent/name';

  public static readonly BUSINESS_DELETE_AGENT_DETAILS = environment.baseUrl + 'business/agent/delete';

  public static readonly BUSINESS_UPDATE_AGENT = environment.baseUrl + 'business/agent/update';

  public static readonly BUSINESS_RECEIPT_RECORDS = environment.baseUrl + 'business/receipt/records';

  public static readonly BUSINESS_RECEIPT_SEARCH_RECORDS = environment.baseUrl + 'business/receipt/search/records';

  public static readonly BUSINESS_ADD_RECEIPT_RECORDS = environment.baseUrl + 'business/receipt/add/records';

  public static readonly BUSINESS_EDIT_RECEIPT_RECORDS = environment.baseUrl + 'business/receipt/edit/records';

  public static readonly BUSINESS_DELETE_RECEIPT_RECORDS = environment.baseUrl + 'business/receipt/delete/records';

  public static readonly BUSINESS_MEMBER_DETAILS = environment.baseUrl + 'business/member/details';

  public static readonly BUSINESS_MEMBER_SEARCH_TRANSMITTAL = environment.baseUrl + 'business/member/search/transmital/record';

  public static readonly BUSINESS_CASUALTY_DETAILS = environment.baseUrl + 'business/casualty/details';

  public static readonly BUSINESS_ADD_CASUALTY_DETAILS = environment.baseUrl + 'business/casualty/add/details';

  public static readonly BUSINESS_DELETE_CASUALTY_DETAILS = environment.baseUrl + 'business/casualty/delete/details';

  public static readonly BUSINESS_INSURED_DETAILS = environment.baseUrl + 'business/member/insured/details';

  public static readonly BUSINESS_UPDATE_MEMBER_DETAILS = environment.baseUrl + 'business/member/update/details';  

  public static readonly BUSINESS_AGENT_RECEIPT_RECORDS = environment.baseUrl + 'business/receipt/agent/records'; 
  
  public static readonly BUSINESS_REGISTER_EMPLOYEE = environment.baseUrl + 'business/employee/register';

  public static readonly BUSINESS_EMPLOYEE_DETAILS = environment.baseUrl + 'business/employee/details';

  public static readonly BUSINESS_REGISTER_CREDENTIALS = environment.baseUrl + 'register/credentials';

  public static readonly BUSINESS_UPDATE_EMPLOYEE = environment.baseUrl + 'business/employee/update';

  public static readonly BUSINESS_DELETE_EMPLOYEE = environment.baseUrl + 'business/employee/delete';

  public static readonly BUSINESS_DASHBOARD_DETAILS = environment.baseUrl + 'business/dashboard/details';

  public static readonly BUSINESS_EMPLOYEE_PDF = environment.baseUrl + 'business/employee/export/pdf';

  public static readonly BUSINESS_BRANCH_MONTHLY_REPORT = environment.baseUrl + 'business/receipt/branch/records';

  public static readonly BUSINESS_TRANSMITTAL_PDF = environment.baseUrl + 'business/member/transmittal/pdf/export';

  public static readonly BUSINESS_MEMBER_PDF = environment.baseUrl + 'business/member/insured/pdf/export';

  public static readonly BUSINESS_AGENT_MONTHLY_SALES_REPORT_PDF = environment.baseUrl + 'business/agent/monthly/sales/report/pdf';

  public static readonly BUSINESS_BRANCH_MONTHLY_SALES_REPORT_PDF = environment.baseUrl + 'business/receipt/branch/monthly/report';

  public static readonly BUSINESS_MONTHLY_CASUALTY_REPORT_PDF = environment.baseUrl + 'business/casualty/monthly/report';

  public static readonly BUSINESS_AGENT_MEMBER_PDF = environment.baseUrl + 'business/agent/member/export/pdf';

  public static readonly BUSINESS_RECEIPT_PDF = environment.baseUrl + 'business/receipt/export/record';

  public static readonly BUSINESS_MEMBER_DETAILS_EXIST = environment.baseUrl + 'business/member/isExist';

  public static readonly BUSINESS_AGENT_PAYMENT_RECORD = environment.baseUrl + 'business/agent/paymentRecord';
}
