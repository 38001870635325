import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { AgentDetailsResponseHandler, AgentMonthlySaleReportModel } from '../model/agent.model';

export interface AgentState {
  agentDetails: AgentDetailsResponseHandler;
  monthlyReport: AgentMonthlySaleReportModel;
}

export function createInitialState(): AgentState {
  return {
    agentDetails: null,
    monthlyReport: null
  };
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'agent' })
export class AgentStore extends Store<AgentState> {
  constructor() {
    super(createInitialState());
  }
}
