import { Component, ElementRef, IterableDiffers, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TransmittalModel, TransmittalPaymentRecordModel } from '../../model/transmittal.model';
import { CommonUtil } from '../../util/common.util';
import { ObjectUtil } from '../../util/object.util';
import { DropDownQuery } from '../../query/dropdown.query';
import { CommonService } from '../../service/common.service';
import { browserRefresh } from 'src/app/app.component';
import { TransmittalQuery } from '../../query/transmittal.query';
import { filter, take } from 'rxjs/operators';
import { BusinessService } from '../../service/business.service';
import { NzMessageService } from 'ng-zorro-antd';
import { UserIdleService } from 'angular-user-idle';
import { Router } from '@angular/router';
import { LoginService } from '../../service/login.service';
import { DropdownModel, DropdownPlanModel } from '../../model/common.model';

@Component({
  selector: 'app-transmittal',
  templateUrl: './transmittal.component.html',
  styleUrls: ['./transmittal.component.scss']
})
export class TransmittalComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private dropDownQuery: DropDownQuery,
    private businessService: BusinessService,
    private transmittalQuery: TransmittalQuery,
    private message: NzMessageService,
    private userIdle: UserIdleService,
    private loginService: LoginService,
    private router: Router) {
      ;
  }

  @ViewChild('inputElement', { static: false }) inputElement: ElementRef;

  protected value = '';
  protected formGroup: FormGroup;
  protected isConfirmLoading = false;
  protected isEdit = false;
  protected dateFormat = 'MMMM dd, yyyy'
  protected isVisible = false;
  protected editCache: { [key: string]: { edit: boolean; data: TransmittalPaymentRecordModel } } = {};
  protected searchControlnumber = '';
  protected searchName = '';
  protected sortControlNumber: string | null = null;
  protected sortName: string | null = null;
  protected sortReceiptNumber: string | null = null;
  protected sortValue: string | null = null;
  protected listOfSearchAddress: string[] = [];
  protected listOfData: Array<TransmittalModel> = [];
  protected dataHolder: Array<TransmittalModel> = [];
  protected listOfDisplayData = [];
  protected listOfRecord: Array<TransmittalPaymentRecordModel> = [];
  protected typeOfPlan = [];
  protected listOfPlan: Array<DropdownPlanModel>;
  protected title = '';
  protected tempFormData = {} as TransmittalModel;
  protected listOfAgent: Array<DropdownModel>;
  protected pagesize = 0;


  ngOnInit(): void {
    this.initForm();
    this.getTransmittalData();
    this.getTypeOfPlan();

    this.sessionLogout();

    this.transmittalQuery.selectError()
      .pipe(filter(err => ObjectUtil.isNotEmpty(err)))
      .subscribe(() => {
        this.message.error('Error encountered in the backend. Please contact with the admin.', {
          nzDuration: 5000
        }).onClose!.pipe().subscribe(() => {
          this.loginService.logout();
          this.router.navigateByUrl('login');
        });
      });
  }

  sessionLogout() {
    this.userIdle.startWatching();
    this.userIdle.onTimerStart().subscribe();
    this.userIdle.onTimeout().subscribe(() => {
      this.userIdle.stopTimer();
      this.userIdle.stopWatching();
      this.loginService.logout();
      this.router.navigateByUrl('login');
    });
  }


  private initForm(): void {
    this.formGroup = this.formBuilder.group({
      controlNumber: [{ value: '', disabled: false }, [Validators.required]],
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      middleName: [null, [Validators.required]],
      agent: [null, [Validators.required]],
      address: [null, [Validators.required]],
      typeOfPlan: [null, [Validators.required]],
      planPrice: [{ value: '', disabled: true }, [Validators.required]],
      contactNumber: [{ value: '', disabled: true }, []]
    });
  }

  private getTransmittalData() {

    if (ObjectUtil.isEmpty(this.listOfDisplayData)) {
      this.businessService.memberTransmittal();
    }

    this.transmittalQuery.select()
      .pipe()
      .subscribe(res => {

        if (res.transmittal && res.transmittal.transmittalDetails.length > 0) {
          this.listOfData = [...res.transmittal.transmittalDetails];
          this.listOfDisplayData = [...this.listOfData];
          this.dataHolder = [...this.listOfData];
          this.pagesize = res.transmittal.totalSize;
        } else {
          this.listOfData = [];
          this.listOfDisplayData = [];
        }

      });
  }

  protected reset(sortBy: string): void {
    this.searchControlnumber = '';
    this.searchName = '';
    // this.search(sortBy);
    // this.listOfDisplayData = [...this.listOfData];
    this.businessService.memberTransmittal();
  }

  getTypeOfPlan() {
    this.commonService.getListOfPlan();
    this.commonService.getListOfAgent();

    this.dropDownQuery.select()
      .subscribe(res => {
        this.listOfPlan = [...res.plans];
        this.typeOfPlan = [];
        this.listOfAgent = [...res.agents];

        if (ObjectUtil.isNotEmpty(this.listOfPlan)) {
          for (var plan of this.listOfPlan) {
            this.typeOfPlan.push({ label: plan.planName, value: plan.planName });
          }
        }
      });
  }


  onChangeTypeOfPlan(value: string) {
    if (ObjectUtil.isNotEmpty(value)) {
      let planPrice = this.listOfPlan.filter(plan => plan.planName === value)[0].memberPrice;
      this.formGroup.get("planPrice").setValue(planPrice);
    }
  }

  protected search(sortBy: string): void {

    if(this.searchName !== '') {
      let request = {
        'controlNumber': '',
        'fullName': ''
      };
  
      if (sortBy === 'controlnumber') {
  
        // const filterFunc = (item: TransmittalModel) => {
        //   return item.controlNumber.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1;
        // };
  
        // const data = this.listOfData.filter((item: TransmittalModel) => filterFunc(item));
        // this.listOfDisplayData = data.sort((a, b) =>
        //   this.sortValue === 'ascend'
        //     ? a[this.sortControlNumber!] > b[this.sortControlNumber!]
        //       ? 1
        //       : -1
        //     : b[this.sortControlNumber!] > a[this.sortControlNumber!]
        //       ? 1
        //       : -1
        // );
  
        request.controlNumber = this.searchControlnumber;
      } else if (sortBy === 'name') {
        // const filterFunc = (item: TransmittalModel) => {
        //   return item.fullName.toLowerCase().indexOf(this.searchValue.toLocaleLowerCase()) !== -1;
        // };
  
        // const data = this.listOfData.filter((item: TransmittalModel) => filterFunc(item));
        // this.listOfDisplayData = data.sort((a, b) =>
        //   this.sortValue === 'ascend'
        //     ? a[this.sortName!] > b[this.sortName!]
        //       ? 1
        //       : -1
        //     : b[this.sortName!] > a[this.sortName!]
        //       ? 1
        //       : -1
        // );
  
        request.fullName = this.searchName;
      }
  
      this.businessService.searchTransmitalRecord(request);
    }
    
  }

  protected formSubmit(): void {
    // this.isConfirmLoading = true;

    for (const i in this.formGroup.controls) {
      this.formGroup.controls[i].markAsDirty();
      this.formGroup.controls[i].updateValueAndValidity();
    }

    let editedValue: TransmittalModel = {} as TransmittalModel;
    editedValue.id = this.tempFormData.id;
    editedValue.controlNumber = this.tempFormData.controlNumber;
    editedValue.agent = this.formGroup.get('agent').value;
    editedValue.firstName = this.formGroup.get('firstName').value;
    editedValue.middleName = this.formGroup.get('middleName').value;
    editedValue.lastName = this.formGroup.get('lastName').value;
    editedValue.address = this.formGroup.get('address').value;
    editedValue.typeOfPlan = this.formGroup.get('typeOfPlan').value;
    editedValue.planPrice = this.formGroup.get('planPrice').value;
    editedValue.contactNumber = this.formGroup.get('contactNumber').value;
    editedValue.paymentRecord = this.tempFormData.paymentRecord;

    this.businessService.updateMember({ request: editedValue }).subscribe(() => {
      this.businessService.memberTransmittal();
      this.message.success('Member details updated successfully!');
      this.modalCancel();
    });


  }

  protected modalCancel(): void {
    this.isVisible = false;
    this.formGroup.reset();
  }

  protected viewDetails(data: TransmittalModel) {
    this.isVisible = true;
    this.isEdit = false;
    this.title = 'View'
    this.setValue('controlNumber', data, true);
    this.setValue('firstName', data, true);
    this.setValue('middleName', data, true);
    this.setValue('lastName', data, true);
    this.setValue('agent', data, true);
    this.setValue('address', data, true);
    this.setValue('typeOfPlan', data, true);
    this.setValue('contactNumber', data, true);

    if (ObjectUtil.isNotEmpty(data.paymentRecord)) {
      this.listOfRecord = [...data.paymentRecord];
      this.updateEditCache();
    } else {
      this.listOfRecord = [];
    }
  }

  protected editDetails(data: TransmittalModel) {

    Object.assign(this.tempFormData, data);
    this.isEdit = true;
    this.isVisible = true;
    this.title = 'Edit'
    this.setValue('controlNumber', data, true);
    this.setValue('firstName', data, false);
    this.setValue('middleName', data, false);
    this.setValue('lastName', data, false);
    this.setValue('agent', data, false);
    this.setValue('address', data, false);
    this.setValue('typeOfPlan', data, false);
    this.setValue('contactNumber', data, false);

    if (ObjectUtil.isNotEmpty(data.paymentRecord)) {
      this.listOfRecord = [...data.paymentRecord];
      this.updateEditCache();
    }
  }

  protected deleteDetails(data: TransmittalModel) {
    this.businessService.deleteMemberDetails(data.id).subscribe(() => {
      this.businessService.memberTransmittal();
      this.message.success('Deleted Successfully!');
    });
  }

  protected setValue(field: string, data: TransmittalModel, isReadOnly: boolean) {
    this.formGroup.get(field).setValue(data[field]);

    if (isReadOnly) {
      this.formGroup.get(field).disable({ onlySelf: isReadOnly });
    } else {
      this.formGroup.get(field).enable();
    }

  }

  protected startEdit(id: string): void {
    this.editCache[id].edit = true;
  }

  protected deleteRecord(id: number): void {
    this.listOfRecord = [...this.listOfRecord.filter(item => item.id != id)];
  }

  protected cancelEdit(id: number): void {
    const index = this.listOfRecord.findIndex(item => item.id === id);
    this.editCache[id] = {
      data: { ...this.listOfRecord[index] },
      edit: false
    };
  }

  protected saveEdit(id: number): void {
    const index = this.listOfRecord.findIndex(item => item.id === id);
    this.editCache[id].data.dateOfPayment = CommonUtil.formatDefaultDate(this.editCache[id].data.dateOfPayment);

    if (ObjectUtil.isNotEqual(this.listOfRecord[index], this.editCache[id].data)) {
      Object.assign(this.listOfRecord[index], this.editCache[id].data);
    }

    this.editCache[id].edit = false;
  }

  protected updateEditCache(): void {
    this.listOfRecord.forEach(item => {
      this.editCache[item.id] = {
        edit: false,
        data: { ...item }
      };
    });
  }

  onChangeInputNumber(value: string): void {
    const reg = /^-?([0-9]*)(\.[0-9]*)?$/;
    if ((!isNaN(+value) && reg.test(value)) || value === '' || value === '-') {
      this.value = value;
    }
    this.inputElement.nativeElement.value = this.value;
  }

  exportToPdf() {
    this.businessService.exportTransmittalPdf(this.listOfDisplayData).subscribe(response => {
      var blob = new Blob([response], { type: 'application/pdf' });

      const data = window.URL.createObjectURL(blob);
      window.open(data, '_blank');
    });
  }

  currentPageDataChange($event: TransmittalModel[]): void {

    if ($event) {
      this.businessService.memberTransmittalPagination(Number($event), 20);
      this.searchName = '';
    }
  }

}

