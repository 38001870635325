import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { TransmittalModel, TransmittalResponse } from '../model/transmittal.model';

export interface TransmittalState {
  transmittal: TransmittalResponse;
}

export function createInitialState(): TransmittalState {
  return {
    transmittal: null
  };
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'transmittal' })
export class TransmittalStore extends Store<TransmittalState> {
  constructor() {
    super(createInitialState());
  }
}
