import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmployeeModel, LoginModelRequest } from '../../model/employee.model';
import { BusinessService } from '../../service/business.service';
import { EmployeeQuery } from '../../query/employee.query';
import { NzMessageService } from 'ng-zorro-antd';
import { DropDownQuery } from '../../query/dropdown.query';
import { CommonService } from '../../service/common.service';
import { CommonUtil } from '../../util/common.util';
import { LoginService } from '../../service/login.service';
import { Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { filter } from 'rxjs/operators';
import { ObjectUtil } from '../../util/object.util';

@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.scss']
})
export class EmployeeDetailsComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private businessService: BusinessService,
    private employeeQuery: EmployeeQuery,
    private dropDownQuery: DropDownQuery,
    private commonService: CommonService,
    private message: NzMessageService,
    private userIdle: UserIdleService,
    private loginService: LoginService,
    private router: Router) { }

  protected formGroup: FormGroup;
  protected formGroupCreds: FormGroup;
  showModal = false;
  showModalCreds = false;
  isEditMode = false;
  isAddNew = false;
  isConfirmLoading = false;
  formAction = '';
  confirmPasswordMsg = 'Please input Confirm Password!';
  listOfEmployee: Array<EmployeeModel> = [];
  listOfRole = [{
    label: 'Admin',
    value: 'Admin'
  }, {
    label: 'Staff',
    value: 'Staff'
  }];
  listOfPostion = [{
    label: 'HR',
    value: 'HR'
  }];
  listOfGender = [];
  listOfEmployeeDropDown = [];
  employeeId = '';

  ngOnInit() {
    this.initForm();
    this.initTable();
    this.initDropDown();
    this.sessionLogout();

    this.dropDownQuery.selectError()
      .pipe(filter(err => ObjectUtil.isNotEmpty(err)))
      .subscribe(() => {
        this.message.error('Error encountered in the backend. Please contact with the admin.', {
          nzDuration: 5000
        }).onClose!.pipe().subscribe(() => {
          this.loginService.logout();
          this.router.navigateByUrl('login');
        });
      });
  }

  sessionLogout() {
    this.userIdle.startWatching();
    this.userIdle.onTimerStart().subscribe();
    this.userIdle.onTimeout().subscribe(() => {
      this.userIdle.stopTimer();
      this.userIdle.stopWatching();
      this.loginService.logout();
      this.router.navigateByUrl('login');
    });
  }

  initDropDown() {
    this.commonService.getListOfGender();
    this.dropDownQuery.select()
      .subscribe(res => {
        this.listOfGender = [...res.gender];
      });
  }

  initTable() {
    this.businessService.getEmployeeDetails();

    this.listOfEmployeeDropDown = [];

    this.employeeQuery.select()
      .pipe()
      .subscribe(res => {
        this.listOfEmployee = [...res.employeeDetails];

        this.listOfEmployee.forEach(value => {
          this.listOfEmployeeDropDown.push({
            label: value.firstName + ' ' + value.lastName,
            value: value.id
          });
        });
      });
  }

  addNewStaff() {
    this.showModal = true;
    this.formAction = 'Add New';
    this.isAddNew = true;
  }

  private initForm(): void {
    this.formGroup = this.formBuilder.group({
      employmentDate: [{ value: '', disabled: false }, [Validators.required]],
      firstName: [{ value: '', disabled: false }, [Validators.required]],
      middleName: [{ value: '', disabled: false }, [Validators.required]],
      lastName: [{ value: '', disabled: false }, [Validators.required]],
      birthday: [{ value: '', disabled: false }, [Validators.required]],
      age: [{ value: '', disabled: false }, [Validators.required]],
      gender: [{ value: '', disabled: false }, [Validators.required]],
      contactNumber: [{ value: '', disabled: false }, [Validators.required]],
      address: [{ value: '', disabled: false }, [Validators.required]],
      position: [{ value: '', disabled: false }, [Validators.required]],
      role: [{ value: '', disabled: false }, [Validators.required]]
    });

    this.formGroupCreds = this.formBuilder.group({
      employeeId: [{ value: '', disabled: false }, [Validators.required]],
      username: [{ value: '', disabled: false }, [Validators.required]],
      password: [{ value: '', disabled: false }, [Validators.required]],
      confirmPassword: [{ value: '', disabled: false }, [Validators.required]]
    });
  }

  modalCancel(): void {
    this.showModal = false;
    this.formGroup.reset();
    this.isAddNew = false;

    const data = {} as EmployeeModel;

    this.setValue('employmentDate', data, false);
    this.setValue('firstName', data, false);
    this.setValue('middleName', data, false);
    this.setValue('lastName', data, false);
    this.setValue('birthday', data, false);
    this.setValue('gender', data, false);
    this.setValue('age', data, false);
    this.setValue('contactNumber', data, false);
    this.setValue('address', data, false);
    this.setValue('position', data, false);
    this.setValue('role', data, false);
  }

  modalCredsCancel(): void {
    this.showModalCreds = false;
    this.formGroup.reset();
  }

  addCreds() {
    this.showModalCreds = true;
  }

  resetForm() {
    this.formGroup.reset();
  }

  submitForm() {
    this.isConfirmLoading = true;
    const form = this.formGroup;

    if (form.valid) {
      let request = form.getRawValue() as EmployeeModel;
      request.employmentDate = CommonUtil.formatDate(request.employmentDate, 'MM-dd-yyyy');
      request.birthday = CommonUtil.formatDate(request.birthday, 'MM-dd-yyyy');

      if (this.isEditMode) {
        request.id = this.employeeId;
        this.businessService.updateEmployee(request).subscribe(() => {
          this.message.success('Employee Updated Successfully!');
          this.formGroup.reset();
          this.businessService.getEmployeeDetails();
          this.showModal = false;
        });
      } else {
        this.businessService.registerNewEmployee(request).subscribe(() => {
          this.message.success('New Employee Added Successfully!');
          this.formGroup.reset();
          this.businessService.getEmployeeDetails();
          this.showModal = false;
        });
        this.listOfEmployeeDropDown = [];
      }

    } else {
      for (const i in this.formGroup.controls) {
        this.formGroup.controls[i].markAsDirty();
        this.formGroup.controls[i].updateValueAndValidity();
      }
    }

    this.isConfirmLoading = false;
  }

  submitCredsForm() {
    const form = this.formGroupCreds;

    if (form.valid) {
      let request = form.getRawValue() as LoginModelRequest;

      if (request.password === request.confirmPassword) {
        this.businessService.registerNewCredentials(request).subscribe(() => {
          this.message.success('New Credentials Created Successfully!');
          form.reset();
          this.showModalCreds = false;
        });
      } else {
        form.get('confirmPassword').markAsDirty();
        form.get('confirmPassword').updateValueAndValidity();

        this.confirmPasswordMsg = 'Password is not matched!';
      }

      this.listOfEmployeeDropDown = [];

    } else {
      for (const i in this.formGroup.controls) {
        this.formGroup.controls[i].markAsDirty();
        this.formGroup.controls[i].updateValueAndValidity();
      }
    }
  }

  viewDetails(data: EmployeeModel) {
    this.showModal = true;
    this.formAction = 'View';
    this.isEditMode = false;
    this.setValue('employmentDate', data, true);
    this.setValue('firstName', data, true);
    this.setValue('middleName', data, true);
    this.setValue('lastName', data, true);
    this.setValue('birthday', data, true);
    this.setValue('gender', data, true);
    this.setValue('age', data, true);
    this.setValue('contactNumber', data, true);
    this.setValue('address', data, true);
    this.setValue('position', data, true);
    this.setValue('role', data, true);
  }

  editDetails(data: EmployeeModel) {
    this.showModal = true;
    this.formAction = 'Edit';
    this.isEditMode = true;
    this.setValue('employmentDate', data, false);
    this.setValue('firstName', data, false);
    this.setValue('middleName', data, false);
    this.setValue('lastName', data, false);
    this.setValue('birthday', data, false);
    this.setValue('gender', data, false);
    this.setValue('age', data, false);
    this.setValue('contactNumber', data, false);
    this.setValue('address', data, false);
    this.setValue('position', data, false);
    this.setValue('role', data, false);
    this.employeeId = data.id;
  }

  deleteDetails(data: EmployeeModel) {

    this.businessService.deleteEmployee(data.id).subscribe(() => {
      this.message.success('Employee deleted successfully!');
      this.businessService.getEmployeeDetails();
      this.listOfEmployeeDropDown = [];
    });
  }

  protected setValue(field: string, data: EmployeeModel, isReadOnly: boolean) {
    this.formGroup.get(field).setValue(data[field]);

    if (isReadOnly) {
      this.formGroup.get(field).disable({ onlySelf: isReadOnly });
    } else {
      this.formGroup.get(field).enable();
    }

  }

  onChangeDateOfBirth(value) {
    this.formGroup.get("age").setValue(CommonUtil.getAge(value));
  }

  exportToPdf() {

    this.businessService.exportPdf(this.listOfEmployee).subscribe(response => {
      var blob = new Blob([response], { type: 'application/pdf' });
      
      const data = window.URL.createObjectURL(blob);
      window.open(data, '_blank');

      // download pdf
      // var link = document.createElement("a");
      // link.href = data;
      // link.download = "Je kar.pdf";
      // link.dispatchEvent(
      //   new MouseEvent("click", {
      //     bubbles: true,
      //     cancelable: true,
      //     view: window
      //   })
      // );
    });
  }

}
