
export class ObjectUtil {

  static isEmpty(value: any): boolean {
    return value === undefined || !value || Object.keys(value).length === 0;
  }

  static isNotEmpty(value: any): boolean {
    return !!value && Object.keys(value).length !== 0;
  }

  static isEqual(object1: any, object2: any): boolean {

    let isAllTrue = [];


    Object.keys(object1).forEach(key => {
      if (object1[key] !== undefined) {
        isAllTrue.push(object1[key] === object2[key]);
      }
      
    });
    return !isAllTrue.includes(false);
  }

  static isNotEqual(object1: any, object2: any): boolean {
    return !this.isEqual(object1, object2);
  }

  static isEqualJsonString(object1: any, object2: any): boolean {
    return JSON.stringify(object1) === JSON.stringify(object2);
  }

  static copyMatchingKeyValues(target, source) {
    return Object.keys(target).forEach(key => {
        if (source[key] !== undefined)
        target[key] = source[key];
    });
}
}
