import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ReceiptState, ReceiptStore } from '../store/receipt.store';

@Injectable({
  providedIn: 'root'
})
export class ReceiptQuery extends Query<ReceiptState> {

  constructor(protected receiptStore: ReceiptStore) {
    super(receiptStore);
  }
}
