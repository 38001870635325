import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReceiptRecordsModel, ReceiptRecordsRequestModel } from '../../model/receipt-records.model';
import { ObjectUtil } from '../../util/object.util';
import { DropdownModel } from '../../model/common.model';
import { DropDownQuery } from '../../query/dropdown.query';
import { CommonService } from '../../service/common.service';
import { browserRefresh } from 'src/app/app.component';
import { ReceiptQuery } from '../../query/receipt.query';
import { BusinessService } from '../../service/business.service';
import { NzMessageService } from 'ng-zorro-antd';
import { CommonUtil } from '../../util/common.util';
import { LoginService } from '../../service/login.service';
import { Route, Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { filter } from 'rxjs/operators';
import { MemberDetails } from '../../model/member-details.model';

@Component({
  selector: 'app-receipt-records',
  templateUrl: './receipt-records.component.html',
  styleUrls: ['./receipt-records.component.scss']
})
export class ReceiptRecordsComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private commonService: CommonService,
    private dropDownQuery: DropDownQuery,
    private businessService: BusinessService,
    private receiptQuery: ReceiptQuery,
    private message: NzMessageService,
    private userIdle: UserIdleService,
    private loginService: LoginService,
    private router: Router) { }

  @ViewChild('inputElement', { static: false }) inputElement: ElementRef;

  value = '';
  title = 'Input a number';
  sortReceiptFn = (a: ReceiptRecordsModel, b: ReceiptRecordsModel): number => Number(a.receiptNumber ) - Number(b.receiptNumber);
  protected formGroup: FormGroup;
  protected listOfReceipt: Array<any>;
  protected listOfData: Array<ReceiptRecordsModel>;
  protected showModal = false;
  protected editMode = false;
  protected searchValue = '';
  protected searchName = '';
  protected sortValue: string | null = null;
  // protected receiptNumber: string | null = null;
  // protected membersName: string | null = null;
  protected membersNameList = [];
  protected filteredMembersNameList = [];
  protected agentNameList = [];
  protected filteredAgentNameList = [];
  protected branchAddressList = [];
  protected filteredBranchAddressList = [];
  protected listOfAgent: Array<DropdownModel>;
  protected listOfBranches: Array<DropdownModel>;
  protected listOfMember: Array<DropdownModel>;
  protected listOfFilteredMember: Array<DropdownModel>;
  protected receiptId = '';
  protected percentage = [];
  protected listOfORType: Array<DropdownModel>;
  protected selectedValue = '';
  pagesize = '0';

  protected loading = false;

  ngOnInit() {
    this.initiateListData();
    this.initForm();
    this.initData();
    this.sessionLogout();

    this.receiptQuery.selectError()
      .pipe(filter(err => ObjectUtil.isNotEmpty(err)))
      .subscribe(() => {
        this.message.error('Error encountered in the backend. Please contact with the admin.', {
          nzDuration: 5000
        }).onClose!.pipe().subscribe(() => {
          this.loginService.logout();
          this.router.navigateByUrl('login');
        });
      });
  }

  sessionLogout() {
    this.userIdle.startWatching();
    this.userIdle.onTimerStart().subscribe();
    this.userIdle.onTimeout().subscribe(() => {
      this.userIdle.stopTimer();
      this.userIdle.stopWatching();
      this.loginService.logout();
      this.router.navigateByUrl('login');
    });
  }

  protected initiateListData() {

    this.listOfORType = [{
      label: 'All',
      value: ''
    },{
      label: 'Provisionary',
      value: 'Provisionary'
    },{
      label: 'Temporary',
      value: 'Temporary'
    }];

    this.businessService.receiptDetails(this.selectedValue);
    this.loading = true;
    this.receiptQuery.select()
      .pipe()
      .subscribe(res => {
        
        if(res.receiptDetails !== null) {
          
          this.listOfData = [...res.receiptDetails.receiptRecords];
          this.pagesize = res.receiptDetails.totalSize;
          this.listOfReceipt = [... this.listOfData.sort((a, b) => {
  
            if (Number(a.receiptNumber) > Number(b.receiptNumber)) {
              return 1;
            }
  
            if (Number(a.receiptNumber) < Number(b.receiptNumber)) {
              return -1;
            }
  
            return 0;
  
          })];

          this.loading = false;
  
        } else {
          this.listOfData = [];
          this.listOfReceipt = [];
        }
        


      });
  }

  private initForm(): void {
    this.formGroup = this.formBuilder.group({
      receiptNumber: [{ value: '', disabled: false }, [Validators.required]],
      dateOfIssuance: [{ value: '', disabled: false }, []],
      memberName: [{ value: '', disabled: false }, [Validators.required]],
      agentName: [{ value: '', disabled: true }, []],
      branchName: [{ value: '', disabled: true }, []],
      amount: [{ value: '', disabled: false }, [Validators.required]],
      percentage: [{ value: '', disabled: false }, []],
      totalCommission: [{ value: '', disabled: true }, []],
      orType: [{ value: '', disabled: false }, []]
    });
  }

  initData() {
    this.initDropDownData();

    this.dropDownQuery.select()
      .subscribe(res => {
        this.listOfAgent = [...res.agents];
        this.listOfBranches = [...res.branches];
        this.listOfMember = [...res.members];
        this.listOfFilteredMember = [...this.listOfMember];
      });
  }

  protected initDropDownData() {
    this.commonService.getListOfAgent();
    this.commonService.getListOfBranch();
    this.commonService.getListOfMember();

    this.percentage = [
      {
        label: '40%',
        value: '40'
      }, {
        label: '25%',
        value: '25'
      }, {
        label: '20%',
        value: '20'
      }, {
        label: '15%',
        value: '15'
      }];
  }

  protected exportToPdf() {
    this.businessService.exportReceiptRecordPdf(this.listOfReceipt).subscribe(response => {
      var blob = new Blob([response], { type: 'application/pdf' });

      const data = window.URL.createObjectURL(blob);
      window.open(data, '_blank');
    });
  }

  protected encodeReceipt() {
    this.showModal = true;
  }

  modalCancel(): void {
    this.showModal = false;
    this.editMode = false;
    this.formGroup.reset();
    this.setValue('receiptNumber', "", false);
    this.setValue('dateOfIssuance', "", false);
    this.setValue('memberName', "", false);
    this.setValue('agentName', "", false);
    this.setValue('branchName', "", false);
    this.setValue('amount', "", false);
    this.inputElement.nativeElement.value = "";
  }

  submitForm() {

    if (this.formGroup.valid) {

      let formRawValue = this.formGroup.getRawValue() as ReceiptRecordsModel;

      let request = {} as ReceiptRecordsRequestModel;

      request.receiptNumber = formRawValue.receiptNumber;
      // request.dateOfIssuance = CommonUtil.formatDate(formRawValue.dateOfIssuance, 'MM-dd-yyyy');
      request.dateOfIssuance = formRawValue.dateOfIssuance;
      
      request.agentId = formRawValue.agentName;
      request.branchName = formRawValue.branchName;
      request.amount = formRawValue.amount;
      request.orType = formRawValue.orType;
      
      let memberId = this.listOfMember.filter(option => option.label.toLowerCase().indexOf(formRawValue.memberName.toLowerCase()) !== -1);

      if(!!memberId) {
        request.memberId = memberId[0].value;
      }


      if (ObjectUtil.isNotEmpty(formRawValue.percentage)) {
        switch (formRawValue.percentage) {
          case '40': {
            request.gross40Percent = formRawValue.totalCommission;
            request.gross25Percent = '';
            request.gross20Percent = '';
            request.gross15Percent = '';
            break;
          }
          case '25': {
            request.gross25Percent = formRawValue.totalCommission;
            request.gross40Percent = '';
            request.gross20Percent = '';
            request.gross15Percent = '';
            break;
          }
          case '20': {
            request.gross20Percent = formRawValue.totalCommission;
            request.gross25Percent = '';
            request.gross40Percent = '';
            request.gross15Percent = '';
            break;
          }
          case '15': {
            request.gross15Percent = formRawValue.totalCommission;
            request.gross25Percent = '';
            request.gross20Percent = '';
            request.gross40Percent = '';
            break;
          }
          default: {
            break;
          }
        }
      }

      if (!this.editMode) {
        this.businessService.addReceiptDetails(request).subscribe(() => {
          this.message.success('Receipt added successfully!');
          this.formGroup.reset();
          this.updateValue('');
          this.showModal = false;
          this.businessService.receiptDetails(this.selectedValue);
        });
      } else {
        request.id = this.receiptId;
        this.businessService.editReceiptDetails(request).subscribe(() => {
          this.message.success('Receipt updated successfully!');
          this.formGroup.reset();
          this.updateValue('');
          this.showModal = false;
          this.businessService.receiptDetails(this.selectedValue);
        });
      }
    } else {
      for (const i in this.formGroup.controls) {
        this.formGroup.controls[i].markAsDirty();
        this.formGroup.controls[i].updateValueAndValidity();
      }
    }
  }

  viewDetails(data: ReceiptRecordsModel) {
    this.showModal = true;
    this.setValue('receiptNumber', data, true);
    this.setValue('dateOfIssuance', data, true);
    this.setValue('amount', data, true);
    this.setValue('branchName', data, true);
    this.setValue('totalCommission', data, true);
    
    this.formGroup.get('orType').setValue(this.getValueFromList(this.listOfORType.find(value => value.label === data.orType), 'label'));
    this.formGroup.get('orType').disable({ onlySelf: true });
    this.formGroup.get('memberName').setValue(this.getValueFromList(this.listOfMember.find(value => value.label === data.memberName), 'label'));
    this.formGroup.get('memberName').disable({ onlySelf: true });
    this.formGroup.get('agentName').setValue(this.getValueFromList(this.listOfAgent.find(value => value.label === data.agentName), 'value'));
    this.formGroup.get('agentName').disable({ onlySelf: true });
    this.formGroup.get('percentage').setValue(this.getValueFromList(this.percentage.find(value => value.value === data.percentage), 'value'));
    this.formGroup.get('percentage').disable({ onlySelf: true });
  }

  editDetails(data: ReceiptRecordsModel) {
    this.showModal = true;
    this.editMode = true;
    this.receiptId = data.id;
    this.setValue('receiptNumber', data, false);
    this.setValue('dateOfIssuance', data, false);
    this.setValue('amount', data, false);
    this.setValue('branchName', data, false);
    this.setValue('totalCommission', data, true);

    this.formGroup.get('orType').setValue(this.getValueFromList(this.listOfORType.find(value => value.label === data.orType), 'label'));
    this.formGroup.get('orType').enable();
    this.formGroup.get('memberName').setValue(this.getValueFromList(this.listOfMember.find(value => value.label === data.memberName), 'label'));
    this.formGroup.get('memberName').enable();
    this.formGroup.get('agentName').setValue(this.getValueFromList(this.listOfAgent.find(value => value.label === data.agentName), 'value'));
    this.formGroup.get('agentName').enable();
    this.formGroup.get('percentage').setValue(this.getValueFromList(this.percentage.find(value => value.value === data.percentage), 'value'));;
    this.formGroup.get('percentage').enable();
  }
  
  protected getValueFromList(value, label: string): string {

    let returnValue = '';

    if(!!value) {
      returnValue = value[label];
    }

    return returnValue;
  }

  protected reset(sortBy: string): void {
    this.searchValue = '';
    // this.search(sortBy);
    this.searchName = '';
    this.searchValue = '';
    this.businessService.receiptDetails(this.selectedValue);
  }

  protected search(sortBy: string): void {

    if(this.searchValue !== '') {
      let request = {
        'receiptNumber': '',
        'name': ''
      };
  
      if (sortBy === 'receiptNumber') {
        request.receiptNumber = this.searchValue;
  
        // const filterFunc = (item: ReceiptRecordsModel) => {
        //   return item.receiptNumber.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1;
        // };
  
        // const data = this.listOfData.filter((item: ReceiptRecordsModel) => filterFunc(item));
        // this.listOfReceipt = data.sort((a, b) => Number(a.receiptNumber) > Number(b.receiptNumber) ? 1 : -1);
      } else if (sortBy === 'membersName') {
        request.name = this.searchName;
        // const filterFunc = (item: ReceiptRecordsModel) => {
        //   return item.memberName.toLowerCase().indexOf(this.searchName.toLowerCase()) !== -1;
        // };
  
        // const data = this.listOfData.filter((item: ReceiptRecordsModel) => filterFunc(item));
        // this.listOfReceipt = data.sort((a, b) => a.memberName > b.memberName ? 1 : -1);
      }
  
      this.businessService.receiptSearchRecords(request);
    }
  }

  onChange(value: string, field: string): void {
    if (ObjectUtil.isNotEmpty(value)) {
      if (field === "membersName") {
        this.filteredMembersNameList = this.membersNameList.filter(option => option.toLowerCase().indexOf(value.toLowerCase()) !== -1);
      } else if (field === "agentName") {
        this.filteredAgentNameList = this.agentNameList.filter(option => option.toLowerCase().indexOf(value.toLowerCase()) !== -1);
      } else if (field === "branchAddress") {
        this.filteredBranchAddressList = this.branchAddressList.filter(option => option.toLowerCase().indexOf(value.toLowerCase()) !== -1);
      }

    }

  }

  onChangeInputNumber(value: string): void {
    if (ObjectUtil.isEmpty(value)) {
      this.formGroup.get('percentage').reset();
      this.formGroup.get('totalCommission').setValue('');
    }
    this.updateValue(value);
  }

  updateValue(value: string): void {
    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
    if ((!isNaN(+value) && reg.test(value)) || value === '' || value === '-') {
      this.value = value;
    }
    this.inputElement.nativeElement.value = this.value;
  }

  formatNumber(value: string): string {
    const string = `${value}`;
    const list = string.split('.');
    const prefix = list[0].charAt(0) === '-' ? '-' : '';
    let num = prefix ? list[0].slice(1) : list[0];
    let result = '';
    while (num.length > 3) {
      result = `,${num.slice(-3)}${result}`;
      num = num.slice(0, num.length - 3);
    }
    if (num) {
      result = num + result;
    }
    return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
  }

  deleteDetails(id: string) {
    this.businessService.deleteReceiptDetails(id).subscribe(() => {
      this.message.success('Receipt records deleted successfully!');
      this.businessService.receiptDetails(this.selectedValue);
    });
  }

  protected setValue(field: string, data: any, isReadOnly: boolean) {
    this.formGroup.get(field).setValue(data[field]);

    if (isReadOnly) {
      this.formGroup.get(field).disable({ onlySelf: isReadOnly });
    } else {
      this.formGroup.get(field).enable();
    }

  }

  protected calculateCommission(percent) {
    const amount = this.formGroup.get('amount').value;
    let percentage = 0;

    if (ObjectUtil.isNotEmpty(amount)) {
      percentage = (parseInt(amount) * parseInt(percent)) / 100;
      this.formGroup.get('totalCommission').setValue(percentage);
    }
  }

  protected populateMemberDetails(value) {

    if (!!value) {
      this.businessService.memberDetails(value).subscribe(member => {
        let memberDetails = member['result'] as MemberDetails;
        this.formGroup.get('agentName').setValue(memberDetails.agent);
        this.formGroup.get('branchName').setValue(memberDetails.branch);
      });
    }
  }

  onChangeOrType(result: string): void {
    this.businessService.receiptDetails(this.selectedValue);
  }

  onChangeMemberName(value: string): void {
    if (ObjectUtil.isNotEmpty(value)) {
      
      this.listOfFilteredMember = this.listOfMember.filter(option => option.label.toLowerCase().indexOf(value.toLowerCase()) !== -1);

      if (!!this.listOfFilteredMember 
          && this.listOfFilteredMember.length > 0 
          && !!this.listOfFilteredMember[0].value) {
        this.businessService.memberDetails(this.listOfFilteredMember[0].value).subscribe(member => {
          let memberDetails = member['result'] as MemberDetails;
          this.formGroup.get('agentName').setValue(memberDetails.agent);
          this.formGroup.get('branchName').setValue(memberDetails.branch);
        });
      }
    } else {
      this.listOfFilteredMember = [...this.listOfMember];
    }
  }

  currentPageDataChange($event: number): void {
    this.loading = true;
    if($event) {
      this.searchValue = '';
      this.businessService.receiptDetailsPage(this.selectedValue, $event.toString(), '20');
      this.loading = false;
    }

    
  }



}
