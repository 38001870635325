import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { ReceiptRecordsModel, ReceiptRecordsPaginatedModel } from '../model/receipt-records.model';

export interface ReceiptState {
  receiptDetails: ReceiptRecordsPaginatedModel;
}

export function createInitialState(): ReceiptState {
  return {
    receiptDetails: null
  };
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'receipt' })
export class ReceiptStore extends Store<ReceiptState> {
  constructor() {
    super(createInitialState());
  }
}
