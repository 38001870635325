import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { EmployeeModel } from '../model/employee.model';

export interface EmployeeState {
  employeeDetails: EmployeeModel[];
}

export function createInitialState(): EmployeeState {
  return {
    employeeDetails: []
  };
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'employee' })
export class EmployeeStore extends Store<EmployeeState> {
  constructor() {
    super(createInitialState());
  }
}
