import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { BranchMonthlySaleReportModel } from '../model/agent.model';

export interface BranchState {
  monthlyReport: BranchMonthlySaleReportModel;
}

export function createInitialState(): BranchState {
  return {
    monthlyReport: null
  };
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'branch' })
export class BranchStore extends Store<BranchState> {
  constructor() {
    super(createInitialState());
  }
}
