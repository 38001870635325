import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UriConstants } from '../constant/uri.constants';
import { DropdownModel, DropdownPlanModel } from '../model/common.model';
import { DropDownStore } from '../store/dropdown.store';
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private http: HttpClient,
    private dropDownStore: DropDownStore) { }

  public getListOfAgent(): void {

    this.http.get<{ result: DropdownModel[] }>(UriConstants.COMMON_AGENT_LIST)
    .pipe()
    .subscribe(res => {
      this.dropDownStore.update({ agents: res.result });
    });
  }

  public getListOfMember(): void {

    this.http.get<{ result: DropdownModel[] }>(UriConstants.COMMON_MEMBER_LIST)
    .pipe()
    .subscribe(res => {
      this.dropDownStore.update({ members: res.result });
    });
  }
  public getListOfGender(): void {

    this.http.get<{ result: DropdownModel[] }>(UriConstants.COMMON_GENDER_LIST)
    .pipe()
    .subscribe(res => {
      this.dropDownStore.update({ gender: res.result });
    });
  }

  public getListOfBranch(): void {

    this.http.get<{ result: DropdownModel[] }>(UriConstants.COMMON_BRANCH_LIST)
    .pipe()
    .subscribe(res => {
      this.dropDownStore.update({ branches: res.result });
    });
  }

  public getListOfPlan(): void {

    this.http.get<{ result: DropdownPlanModel[] }>(UriConstants.COMMON_PLAN_LIST)
    .pipe()
    .subscribe(res => {
      this.dropDownStore.update({ plans: res.result });
    });
  }
}
