import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { DropdownModel, DropdownPlanModel } from '../model/common.model';

export interface DropDownState {
  agents: DropdownModel[];
  gender: DropdownModel[];
  branches: DropdownModel[];
  plans: DropdownPlanModel[];
  members: DropdownModel[];
}

export function createInitialState(): DropDownState {
  return {
    agents: [],
    gender: [],
    branches: [],
    plans: [],
    members: []
  };
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'dropdown' })
export class DropDownStore extends Store<DropDownState> {
  constructor() {
    super(createInitialState());
  }
}
