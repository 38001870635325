import { Component, OnInit } from '@angular/core';
import { User } from '../../model/user.model';
import { filter } from 'rxjs/operators';
import { DashboardQuery } from '../../query/dashboard.query';
import { BusinessService } from '../../service/business.service';
import { ObjectUtil } from '../../util/object.util';
import { Router } from '@angular/router';
import { LoginService } from '../../service/login.service';
import { NzMessageService } from 'ng-zorro-antd';
import { UserIdleService } from 'angular-user-idle';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public user: User[];
  public name: string;

  protected totalActiveAgent = 0;
  protected totalActiveMember = 0;
  protected totalCasualty = 0;
  protected totalEmployees = 0;

  constructor(
    private dashboardQuery: DashboardQuery,
    private businessService: BusinessService,
    private router: Router,
    private loginService: LoginService,
    private message: NzMessageService,
    private userIdle: UserIdleService) { }

  ngOnInit() {


    this.userIdle.startWatching();
    this.userIdle.onTimerStart().subscribe();
    this.userIdle.onTimeout().subscribe(() => {
      this.userIdle.stopTimer();
      this.userIdle.stopWatching();
      this.loginService.logout();
      this.router.navigateByUrl('login');
    });

    this.businessService.dashboardDetails();
    this.dashboardQuery.select().subscribe(res => {
      if (ObjectUtil.isNotEmpty(res) && ObjectUtil.isNotEmpty(res.dashboardDetails)) {
        this.totalActiveMember = res.dashboardDetails.totalActiveMember;
        this.totalCasualty = res.dashboardDetails.totalCasualty;
        this.totalEmployees = res.dashboardDetails.totalEmployees;
        this.totalActiveAgent = res.dashboardDetails.totalActiveAgent;
      }
    });

    this.dashboardQuery.selectError()
      .pipe(filter(err => ObjectUtil.isNotEmpty(err)))
      .subscribe(err => {
        this.message.error('Error encountered in the backend. Please contact with the admin.', {
          nzDuration: 5000
        }).onClose!.pipe().subscribe(() => {
          this.loginService.logout();
          this.router.navigateByUrl('login');
        });
      });

  }


}
