import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { InsuredState, InsuredStore } from '../store/insured.store';

@Injectable({
  providedIn: 'root'
})
export class InsuredQuery extends Query<InsuredState> {

  constructor(protected insuredStore: InsuredStore) {
    super(insuredStore);
  }
}
