import { Component, OnInit } from '@angular/core';
import { MonthlyCasualtyRecordModel } from '../../model/casualty.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ObjectUtil } from '../../util/object.util';
import { CommonService } from '../../service/common.service';
import { DropDownQuery } from '../../query/dropdown.query';
import { BusinessService } from '../../service/business.service';
import { MemberDetails } from '../../model/member-details.model';
import { CasualtyQuery } from '../../query/casualty.query';
import { NzMessageService } from 'ng-zorro-antd';
import { CommonUtil } from '../../util/common.util';
import { Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { LoginService } from '../../service/login.service';
import { filter } from 'rxjs/operators';
import { DropdownModel, DropdownPlanModel } from '../../model/common.model';

@Component({
  selector: 'app-monthly-casualty-record',
  templateUrl: './monthly-casualty-record.component.html',
  styleUrls: ['./monthly-casualty-record.component.scss']
})
export class MonthlyCasualtyRecordComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private commonService: CommonService,
    private dropDownQuery: DropDownQuery,
    private businessService: BusinessService,
    private casualtyQuery: CasualtyQuery,
    private message: NzMessageService,
    private userIdle: UserIdleService,
    private loginService: LoginService,
    private router: Router) { }

  protected formGroup: FormGroup;
  protected listOfData: Array<MonthlyCasualtyRecordModel>;
  protected listOfMonthlyCasualtyRecord: Array<any>;
  protected searchValue = '';
  protected sortValue: string | null = null;
  protected specifiedBranch: string | null = null;
  protected nameOfDeceased: string | null = null;
  protected showModal = false;
  protected editMode = false;
  protected isConfirmLoading = false;
  protected listOfBranch = [];
  protected filteredOfBranch = [];
  protected listOfDeceasedMember = [];
  protected filteredListOfDeceasedMember = [];
  protected membershipTypeOption = [];
  protected typeOfPlan = [];
  protected listOfPlan: Array<DropdownPlanModel>;
  protected formIsDisable = false;
  protected formAction = '';
  protected memberId = '';

  protected listOfMember = [];
  protected listOfORType = [];
  protected listOfFilteredAgent: Array<DropdownModel>;
  protected listOfAgent: Array<DropdownModel>;

  formatterCurrency = (value: number) => `₱ ${value}`;
  parserCurrency = (value: string) => value.replace('₱ ', '');

  formatterPercentage = (value: number) => `% ${value}`;
  parserPercentage = (value: string) => value.replace('% ', '');


  ngOnInit() {
    this.initiateListData();
    this.initForm();
    this.getMembershipType();
    this.initiateDropDownData();

    this.casualtyQuery.select()
      .subscribe(res => {
        this.listOfData = [...res.casualtyDetails];
        this.listOfMonthlyCasualtyRecord = [...this.listOfData];
      });
    this.sessionLogout();

    this.casualtyQuery.selectError()
      .pipe(filter(err => ObjectUtil.isNotEmpty(err)))
      .subscribe(() => {
        this.message.error('Error encountered in the backend. Please contact with the admin.', {
          nzDuration: 5000
        }).onClose!.pipe().subscribe(() => {
          this.loginService.logout();
          this.router.navigateByUrl('login');
        });
      });
  }

  sessionLogout() {
    this.userIdle.startWatching();
    this.userIdle.onTimerStart().subscribe();
    this.userIdle.onTimeout().subscribe(() => {
      this.userIdle.stopTimer();
      this.userIdle.stopWatching();
      this.loginService.logout();
      this.router.navigateByUrl('login');
    });
  }

  protected initiateDropDownData() {
    this.dropDownQuery.select()
      .subscribe(res => {
        this.listOfBranch = [...res.branches];
        this.filteredOfBranch = [...this.listOfBranch];

        this.listOfPlan = [...res.plans];
        this.getTypeOfPlan();

        this.listOfMember = [...res.members];
        this.filteredListOfDeceasedMember = [...this.listOfMember];
        this.listOfAgent = [...res.agents];
        this.listOfFilteredAgent = [...this.listOfAgent];
      });

      this.listOfORType = [{
        label: 'Provisionary',
        value: 'Provisionary'
      },{
        label: 'Temporary',
        value: 'Temporary'
      }];
  }

  protected initiateListData() {

    this.businessService.casualtyDetails();

    this.commonService.getListOfBranch();
    this.commonService.getListOfPlan();
    this.commonService.getListOfMember();
    this.commonService.getListOfAgent();
  }

  private initForm(): void {
    this.formGroup = this.formBuilder.group({
      date: [{ value: '', disabled: false }, [Validators.required]],
      firstName: [{ value: '', disabled: false }, [Validators.required]],
      middleName: [{ value: '', disabled: false }, [Validators.required]],
      lastName: [{ value: '', disabled: false }, [Validators.required]],
      address: [{ value: '', disabled: false }, [Validators.required]],
      membershipType: [{ value: '', disabled: false }, [Validators.required]],
      branch: [{ value: '', disabled: false }, [Validators.required]],
      typeOfPlan: [{ value: '', disabled: false }, [Validators.required]],
      planPrice: [{ value: '', disabled: true }, [Validators.required]],
      totalContribution: [{ value: '', disabled: true }, [Validators.required]],
      discount: [{ value: '', disabled: false }, [Validators.required]],
      receiptNumber: [{ value: '', disabled: false }, [Validators.required]],
      cashDeposit: [{ value: '', disabled: false }, [Validators.required]],
      amountPaid: [{ value: '', disabled: false }, [Validators.required]],
      balance: [{ value: '', disabled: false }, [Validators.required]],
      additionalIncome: [{ value: '', disabled: false }, []],
      additionalDiscount: [{ value: '', disabled: false }, []],
      governmentBurialAssistance: [{ value: '', disabled: false }, []],
      note: [{ value: '', disabled: false }, []],
      status: [{ value: '', disabled: false }, []],
      orType: [{ value: '', disabled: false }, [Validators.required]],
      agent: [{ value: '', disabled: false }, [Validators.required]]
    });
  }

  protected reset(sortBy: string): void {
    this.searchValue = '';
    this.search(sortBy);
  }

  protected search(sortBy: string): void {

    if (sortBy === 'specifiedBranch') {

      const filterFunc = (item: MonthlyCasualtyRecordModel) => {
        return item.branch.indexOf(this.searchValue) !== -1;
      };

      const data = this.listOfData.filter((item: MonthlyCasualtyRecordModel) => filterFunc(item));
      this.listOfMonthlyCasualtyRecord = data.sort((a, b) =>
        this.sortValue === 'ascend'
          ? a[this.specifiedBranch!] > b[this.specifiedBranch!]
            ? 1
            : -1
          : b[this.specifiedBranch!] > a[this.specifiedBranch!]
            ? 1
            : -1
      );
    } else if (sortBy === 'nameOfDeceased') {
      const filterFunc = (item: MonthlyCasualtyRecordModel) => {
        return item.firstName.indexOf(this.searchValue) !== -1;
      };

      const data = this.listOfData.filter((item: MonthlyCasualtyRecordModel) => filterFunc(item));
      this.listOfMonthlyCasualtyRecord = data.sort((a, b) =>
        this.sortValue === 'ascend'
          ? a[this.nameOfDeceased!] > b[this.nameOfDeceased!]
            ? 1
            : -1
          : b[this.nameOfDeceased!] > a[this.nameOfDeceased!]
            ? 1
            : -1
      );
    }
  }

  protected encodeCasualty() {
    this.formAction = "Input";
    if (this.formIsDisable) {
      this.formGroup.enable();
    }
    this.showModal = true;
    this.editMode = true;
  }

  modalCancel(): void {
    this.showModal = false;
    this.editMode = false;
    this.resetForm(true);
  }

  submitForm() {
    this.isConfirmLoading = true;
    const form = this.formGroup;

    let request = form.getRawValue() as MonthlyCasualtyRecordModel;

    

    if (form.valid) {

      let request = form.getRawValue() as MonthlyCasualtyRecordModel;

      request.memberId = this.memberId;
      request.date = CommonUtil.formatDate(request.date, 'MM-dd-yyyy');

      let agentName = this.listOfAgent.filter(option => option.label.toLowerCase().indexOf(request.agent.toLowerCase()) !== -1);
      if (!!agentName && !!agentName[0] && !!agentName[0].value) {
        request.agent = agentName[0].value;
      }

      this.businessService.addCasualty(request).subscribe(() => {
        this.message.success('Casualty added successfully!');
        this.initiateListData();
        this.showModal = false;
        this.resetForm(true);
      });
    } else {
      for (const i in this.formGroup.controls) {
        this.formGroup.controls[i].markAsDirty();
        this.formGroup.controls[i].updateValueAndValidity();
      }
    }

    this.isConfirmLoading = false;
  }

  resetForm(firstNameIncluded: boolean) {
    this.memberId = '';
    this.formGroup.get('date').reset();
    if (firstNameIncluded) {
      this.formGroup.get('firstName').setValue('');
    }

    this.formGroup.get('middleName').setValue('');
    this.formGroup.get('lastName').setValue('');
    this.formGroup.get('address').setValue('');
    this.formGroup.get('membershipType').reset();
    this.formGroup.get('branch').reset();
    this.formGroup.get('typeOfPlan').reset();
    this.formGroup.get('planPrice').setValue('');
    this.formGroup.get('totalContribution').setValue('');
    this.formGroup.get('receiptNumber').setValue('');
    this.formGroup.get('cashDeposit').setValue('');
    this.formGroup.get('amountPaid').setValue('');
    this.formGroup.get('balance').setValue('');
  }

  fieldSelected(event: any, name: any) {
    if (event.isUserInput) {
      this.businessService.memberDetails(name.value).subscribe(member => {

        this.memberId = name.value;
        let memberDetails = member['result'] as MemberDetails;
        this.formGroup.get('firstName').setValue(memberDetails.firstName);
        this.formGroup.get('middleName').setValue(memberDetails.middleName);
        this.formGroup.get('lastName').setValue(memberDetails.lastName);
        this.formGroup.get('address').setValue(memberDetails.permanentAddress);
        this.formGroup.get('membershipType').setValue('Member');
        this.formGroup.get('branch').setValue(memberDetails.branch);
        this.formGroup.get('typeOfPlan').setValue(memberDetails.typeOfPlan);
        this.formGroup.get('planPrice').setValue(memberDetails.planPrice);
        this.formGroup.get('totalContribution').setValue(memberDetails.totalContribution);
        this.formGroup.get('receiptNumber').setValue('');
        this.formGroup.get('cashDeposit').setValue(memberDetails.cashDeposit);
        this.formGroup.get('amountPaid').setValue(memberDetails.amountPaid);
        this.formGroup.get('balance').setValue(memberDetails.balance);

        let agentName = this.listOfAgent.filter(option => option.value.toLowerCase().indexOf(memberDetails.agent.toLowerCase()) !== -1);
        if (!!agentName && !!agentName[0] && !!agentName[0].label) {
          this.formGroup.get('agent').setValue(agentName[0].label);
        }
        
      });

    }
  }

  getMembershipType() {
    this.membershipTypeOption = [{
      label: "Member",
      value: "Member"
    }, {
      label: "Walk-in",
      value: "Walk-in"
    }];
  }

  getTypeOfPlan() {
    this.typeOfPlan = [];

    if (ObjectUtil.isNotEmpty(this.listOfPlan)) {
      for (var plan of this.listOfPlan) {
        this.typeOfPlan.push({ label: plan.planName, value: plan.id });
      }
    }
  }

  onChange(value: string): void {
    if (ObjectUtil.isNotEmpty(value)) {
      this.filteredListOfDeceasedMember = this.listOfMember.filter(option => option.label.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.filteredListOfDeceasedMember = [...this.listOfMember];
      this.resetForm(false);
      this.formGroup.get('membershipType').setValue('Walk-in');
    }
  }

  onChangeTypeOfPlan(value: string) {
    if (ObjectUtil.isNotEmpty(value) && ObjectUtil.isNotEmpty(this.listOfPlan)) {
      let filterPrice = this.listOfPlan.filter(plan => plan.planName === value);
      if (ObjectUtil.isNotEmpty(filterPrice)) {
        let planPrice = '';
        if (this.formGroup.get('membershipType').value === 'Walk-in') {
          planPrice = filterPrice[0].walkInPrice;
        } else {
          planPrice = filterPrice[0].memberPrice;
        }
        
        this.formGroup.get("planPrice").setValue(planPrice);
      }
    }
  }

  onChangeMembershipType() {
    this.formGroup.get("typeOfPlan").reset();
    this.formGroup.get("planPrice").reset();
  }

  viewRecord(data: MonthlyCasualtyRecordModel) {

    this.formAction = "View";
    this.showModal = true;
    this.editMode = false;
    this.setValue('branch', data, true);
    this.setValue('date', data, true);
    this.setValue('firstName', data, true);
    this.setValue('middleName', data, true);
    this.setValue('lastName', data, true);
    this.setValue('address', data, true);
    this.setValue('membershipType', data, true);
    this.setValue('typeOfPlan', data, true);
    this.setValue('totalContribution', data, true);
    this.setValue('discount', data, true);
    this.setValue('receiptNumber', data, true);
    this.setValue('cashDeposit', data, true);
    this.setValue('amountPaid', data, true);
    this.setValue('balance', data, true);
  }

  editRecord(data: MonthlyCasualtyRecordModel) {
    this.formAction = "Edit";
    this.showModal = true;
    this.editMode = true;
    this.setValue('branch', data, false);
    this.setValue('date', data, false);
    this.setValue('firstName', data, false);
    this.setValue('middleName', data, false);
    this.setValue('lastName', data, false);
    this.setValue('address', data, false);
    this.setValue('membershipType', data, false);
    this.setValue('typeOfPlan', data, false);
    this.setValue('totalContribution', data, false);
    this.setValue('discount', data, false);
    this.setValue('receiptNumber', data, false);
    this.setValue('cashDeposit', data, false);
    this.setValue('amountPaid', data, false);
    this.setValue('balance', data, false);
  }

  deleteRecord(id: string) {
    this.businessService.deleteCasualty(id).subscribe(() => {
      this.message.success('Casualty records deleted successfully!');
      this.businessService.casualtyDetails();
    });

  }

  setValue(field: string, data: MonthlyCasualtyRecordModel, isReadOnly: boolean) {
    this.formGroup.get(field).setValue(data[field])
    this.formIsDisable = isReadOnly;
    if (isReadOnly) {
      this.formGroup.get(field).disable();
    } else {
      this.formGroup.get(field).enable();
    }

  }

  exportToPdf() {
    this.businessService.exportMonthtyCasualtyRecordPdf(this.listOfMonthlyCasualtyRecord).subscribe(response => {
      var blob = new Blob([response], { type: 'application/pdf' });

      const data = window.URL.createObjectURL(blob);
      window.open(data, '_blank');
    });
  }

  onChangeAgent(value: string): void {
    if (ObjectUtil.isNotEmpty(value)) {
      this.listOfFilteredAgent = this.listOfAgent.filter(option => option.label.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.listOfFilteredAgent = [...this.listOfAgent];
    }
  }

}