import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { DropDownState, DropDownStore } from '../store/dropdown.store';

@Injectable({
  providedIn: 'root'
})
export class DropDownQuery extends Query<DropDownState> {

  constructor(protected dropDownStore: DropDownStore) {
    super(dropDownStore);
  }
}
