import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BeneficiaryData, MemberDetailsFormModel } from '../../model/application-form.model';
import { InsuredQuery } from '../../query/insured.query';
import { BusinessService } from '../../service/business.service';
import { DropdownModel, DropdownPlanModel } from '../../model/common.model';
import { ObjectUtil } from '../../util/object.util';
import { CommonService } from '../../service/common.service';
import { DropDownQuery } from '../../query/dropdown.query';
import { NzMessageService } from 'ng-zorro-antd';
import { Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { LoginService } from '../../service/login.service';
import { filter } from 'rxjs/operators';
import { CommonUtil } from '../../util/common.util';

@Component({
  selector: 'app-insured-members',
  templateUrl: './insured-members.component.html',
  styleUrls: ['./insured-members.component.scss']
})
export class InsuredMembersComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private businessService: BusinessService,
    private insuredQuery: InsuredQuery,
    private dropDownQuery: DropDownQuery,
    private commonService: CommonService,
    private message: NzMessageService,
    private userIdle: UserIdleService,
    private loginService: LoginService,
    private router: Router) { }

  @ViewChild('contactNumberElement', { static: false }) contactNumberElement: ElementRef;
  @ViewChild('contactNumberTableElement', { static: false }) contactNumberTableElement: ElementRef;
  i = 0;
  editCache: { [key: string]: { edit: boolean; data: BeneficiaryData } } = {};
  listOfBeneficiaryData: BeneficiaryData[] = [];
  protected value = '';
  protected listOfAgent: Array<DropdownModel>;
  protected listOfPlanDisplay: Array<DropdownModel>;
  protected listOfGender: Array<DropdownModel>;
  protected listOfBranches: Array<DropdownModel>;
  protected listOfPlan: Array<DropdownPlanModel>;

  protected formGroup: FormGroup;
  protected listOfInsuredMembers: Array<any>;
  protected listOfData: Array<MemberDetailsFormModel>;
  protected isVisible = false;
  protected searchValue = '';
  protected sortValue: string | null = null;
  protected membersName: string | null = null;
  protected branchAddress: string | null = null;
  protected actionForm = '';
  protected isEdit = false;
  protected memberId = '';
  protected selectedValue = 'Active';
  protected age = '';

  ngOnInit() {
    this.initForm();
    this.initData();
    this.initiateListData();

    this.sessionLogout();

    this.insuredQuery.selectError()
      .pipe(filter(err => ObjectUtil.isNotEmpty(err)))
      .subscribe(() => {
        this.message.error('Error encountered in the backend. Please contact with the admin.', {
          nzDuration: 5000
        }).onClose!.pipe().subscribe(() => {
          this.loginService.logout();
          this.router.navigateByUrl('login');
        });
      });
  }

  sessionLogout() {
    this.userIdle.startWatching();
    this.userIdle.onTimerStart().subscribe();
    this.userIdle.onTimeout().subscribe(() => {
      this.userIdle.stopTimer();
      this.userIdle.stopWatching();
      this.loginService.logout();
      this.router.navigateByUrl('login');
    });
  }

  initForm() {
    this.formGroup = this.formBuilder.group({
      dateofApplication: [null, [Validators.required]],
      firstName: [null, [Validators.required]],
      middleName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      dateOfBirth: [],
      gender: [],
      placeOfBirth: [],
      occupation: [],
      permanentAddress: [null, [Validators.required]],
      contactNumber: [],
      typeOfPlan: [null, [Validators.required]],
      planPrice: [{ value: '', disabled: true }, [Validators.required]],
      membershipFee: [null, [Validators.required]],
      orNumber: [null, [Validators.required]],
      agent: [null, [Validators.required]],
      branch: [null, [Validators.required]],
      dateSigned: [null, [Validators.required]]
    });
  }

  initData() {
    this.initDropDownData();

    this.dropDownQuery.select()
      .subscribe(res => {
        this.listOfAgent = [...res.agents];
        this.listOfGender = [...res.gender];
        this.listOfBranches = [...res.branches];
        this.listOfPlan = [...res.plans];
        this.getTypeOfPlan();
      });
  }

  getTypeOfPlan() {
    this.listOfPlanDisplay = [];

    if (ObjectUtil.isNotEmpty(this.listOfPlan)) {
      for (var plan of this.listOfPlan) {
        this.listOfPlanDisplay.push({ label: plan.planName, value: plan.id });
      }
    }
  }

  protected initDropDownData() {
    this.commonService.getListOfAgent();
    this.commonService.getListOfGender();
    this.commonService.getListOfBranch();
    this.commonService.getListOfPlan();
  }

  protected initiateListData() {

    this.businessService.insuredDetails(this.selectedValue);

    this.insuredQuery.select()
      .subscribe(res => {
        this.listOfData = [...res.insuredDetails];
        this.listOfInsuredMembers = [...this.listOfData];
      });
  }

  protected exportToPdf() {

    this.businessService.exportMemberPdf(this.listOfInsuredMembers).subscribe(response => {
      var blob = new Blob([response], { type: 'application/pdf' });
      
      const data = window.URL.createObjectURL(blob);
      window.open(data, '_blank');
    });

  }

  protected viewMemberForm(data: MemberDetailsFormModel) {
    this.isVisible = true;
    this.actionForm = 'View';
    this.isEdit = false;
    this.setValue('dateofApplication', data, true);
    this.setValue('firstName', data, true);
    this.setValue('middleName', data, true);
    this.setValue('lastName', data, true);
    this.setValue('dateOfBirth', data, true);
    this.setValue('gender', data, true);
    this.setValue('placeOfBirth', data, true);
    this.setValue('occupation', data, true);
    this.setValue('permanentAddress', data, true);
    this.setValue('contactNumber', data, true);
    this.setValue('typeOfPlan', data, true);
    this.setValue('planPrice', data, true);
    this.setValue('membershipFee', data, true);
    this.setValue('orNumber', data, true);
    this.setValue('branch', data, true);
    this.setValue('dateSigned', data, true);
    this.formGroup.get('agent').setValue(this.listOfAgent.find(value => value.label === data.agent).value);
    this.formGroup.get('agent').disable({ onlySelf: true });
    this.age = data.age;
    if (ObjectUtil.isNotEmpty(data.beneficiaryDetails)) {
      this.listOfBeneficiaryData = [...data.beneficiaryDetails];
      this.updateEditCache();
    }
  }

  protected editMemberForm(data: MemberDetailsFormModel) {
    this.isVisible = true;
    this.actionForm = 'Edit';
    this.isEdit = true;
    this.memberId = data.id;
    this.setValue('dateofApplication', data, false);
    this.setValue('firstName', data, false);
    this.setValue('middleName', data, false);
    this.setValue('lastName', data, false);
    this.setValue('dateOfBirth', data, false);
    this.setValue('gender', data, false);
    this.setValue('placeOfBirth', data, false);
    this.setValue('occupation', data, false);
    this.setValue('permanentAddress', data, false);
    this.setValue('contactNumber', data, false);
    this.setValue('typeOfPlan', data, false);
    this.setValue('planPrice', data, false);
    this.setValue('membershipFee', data, false);
    this.setValue('orNumber', data, false);
    this.setValue('branch', data, false);
    this.setValue('dateSigned', data, false);
    this.formGroup.get('agent').setValue(this.listOfAgent.find(value => value.label === data.agent).value);
    this.formGroup.get('agent').enable();
    if (ObjectUtil.isNotEmpty(data.beneficiaryDetails)) {
      this.listOfBeneficiaryData = [...data.beneficiaryDetails];
      this.updateEditCache();
    }

  }

  modalCancel(): void {
    this.isVisible = false;
    this.isEdit = false;
    this.listOfBeneficiaryData = [];
    this.formGroup.reset();
    this.memberId = '';
  }

  submitForm() {

    const form = this.formGroup;
  
    if(form.valid) {
      let request = form.getRawValue() as MemberDetailsFormModel;
      request.beneficiaryDetails = this.listOfBeneficiaryData;
      request.id = this.memberId;
      request.age = this.age;
      this.businessService.updateMemberDetails(request)
        .pipe()
        .subscribe(() => {
          this.message.success('Member details updated successfully!');
          this.businessService.insuredDetails(this.selectedValue);
          this.modalCancel();
        });
    } else {
      for (const i in form.controls) {
        form.controls[i].markAsDirty();
        form.controls[i].updateValueAndValidity();
      }
    }
  }

  protected deleteDetails(data: MemberDetailsFormModel) {
    this.businessService.deleteMemberDetails(data.id).subscribe(() => {
      this.businessService.insuredDetails(this.selectedValue);
      this.message.success('Deleted Successfully!');
    });
  }

  protected reset(sortBy: string): void {
    this.searchValue = '';
    this.search(sortBy);
  }

  protected search(sortBy: string): void {

    if (sortBy === 'membersName') {

      const filterFunc = (item: MemberDetailsFormModel) => {
        return item.firstName.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1;
      };

      const data = this.listOfData.filter((item: MemberDetailsFormModel) => filterFunc(item));
      this.listOfInsuredMembers = data.sort((a, b) =>
        this.sortValue === 'ascend'
          ? a[this.membersName!] > b[this.membersName!]
            ? 1
            : -1
          : b[this.membersName!] > a[this.membersName!]
            ? 1
            : -1
      );
    }
  }

  protected setValue(field: string, data: MemberDetailsFormModel, isReadOnly: boolean) {
    this.formGroup.get(field).setValue(data[field]);

    if (isReadOnly) {
      this.formGroup.get(field).disable({ onlySelf: isReadOnly });
    } else {
      this.formGroup.get(field).enable();
    }

  }

  onChangeTypeOfPlan(value: string) {
    if (ObjectUtil.isNotEmpty(value)) {
      let planPrice = this.listOfPlan.filter(plan => plan.planName === value)[0].memberPrice;
      this.formGroup.get("planPrice").setValue(planPrice);
    }
  }

  addRow(): void {

    if (this.listOfBeneficiaryData.length < 3) {
      this.listOfBeneficiaryData = [
        ...this.listOfBeneficiaryData,
        {
          id: `${this.i}`,
          firstName: ``,
          middleName: ``,
          lastName: ``,
          address: ``,
          contactNumber: ``,
          relationship: ``
        }
      ];

      this.editCache[this.i] = {
        edit: true,
        data: { ...this.listOfBeneficiaryData[this.i] }
      };

      this.i++;
    }
  }

  startEdit(id: string): void {
    this.editCache[id].edit = true;
  }

  cancelEdit(id: string): void {
    this.listOfBeneficiaryData = this.listOfBeneficiaryData.filter(d => d.id !== id);
  }

  saveEdit(id: string): void {
    const index = this.listOfBeneficiaryData.findIndex(item => item.id === id);
    Object.assign(this.listOfBeneficiaryData[index], this.editCache[id].data);
    this.editCache[id].edit = false;
  }

  updateEditCache(): void {
    this.listOfBeneficiaryData.forEach(item => {
      this.editCache[item.id] = {
        edit: false,
        data: { ...item }
      };
    });
  }

  onChangeInputNumber(value: string, field: string): void {
    this.updateValue(value, field);
  }

  updateValue(value: string, field: string): void {
    const reg = /^-?([0-9]*)(\.[0-9]*)?$/;
    if ((!isNaN(+value) && reg.test(value)) || value === '' || value === '-') {
      this.value = value;
    }

    if (field === 'contactNumberElement') {
      this.contactNumberElement.nativeElement.value = this.value;
    } else if (field === 'contactNumberTableElement') {
      this.contactNumberTableElement.nativeElement.value = this.value;
    }

  }

  onChange(result: string): void {
    this.businessService.insuredDetails(this.selectedValue);
  }

  onChangeDateOfBirth(value) {
    this.age = CommonUtil.getAge(value);
  }

}
