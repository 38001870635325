import { DatePipe } from "@angular/common";

export class CommonUtil {
  static formatDate(value: any, format: string): string {

    let datePipe = new DatePipe('en-US');
    const formatValue = datePipe.transform(value, format);

    return formatValue;
  }

  static formatDefaultDate(value: any): string {

    let datePipe = new DatePipe('en-US');
    const formatValue = datePipe.transform(value, 'MMMM dd, yyyy');

    return formatValue;
  }

  static getAge(dob: string): string {
    let age = '';
    
    if(!!dob) {
      const bdate = new Date(dob);
      const timeDiff = Math.abs(Date.now() - bdate.getTime() );
      age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365).toString();
    }
    return age;
  }
}
