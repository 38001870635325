import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AgentState, AgentStore } from '../store/agent.store';

@Injectable({
  providedIn: 'root'
})
export class AgentQuery extends Query<AgentState> {

  constructor(protected agentStore: AgentStore) {
    super(agentStore);
  }
}
